<template>
  <v-dialog
    hide-overlay
    scrollable
    v-model="show"
    max-width="420"
    style="z-index: 1; background-color: #f3f3f3"
  >
    <v-card
      class="mx-auto"
      max-width="420"
      style="
        position: absolute;
        top: 0px;
        left: 440px;
        background-color: #f3f3f3;
      "
    >
      <!-- <v-btn color="primary" flat @click.stop="show = false">Close</v-btn> -->
      <!-- stopId:{{ stopId }} -->
      <div class="rowmh">
        <table class="table table-borderless" style="border: none !important">
          <tr>
            <td>
              <div class="headerLine3">
                <button
                  v-if="!isOptimized"
                  class="btn btnl1-ghost btn-block btn-sm"
                  @click="duplicateStop()"
                  type="submit"
                  :disabled="isOptimized"
                >
                  <span class="material-icons"> content_copy </span>

                  Duplicate
                </button>
              </div>
            </td>

            <td>
              <div class="headerLine3">
                <button
                  v-if="!isOptimized"
                  class="btn btn-danger btn-block btn-ghost btn-sm"
                  @click="deleteStop()"
                  type="submit"
                  :disabled="isOptimized"
                >
                  <span class="material-icons"> delete </span>
                  Delete
                </button>
              </div>
            </td>
            <td style="width: 80%; text-align: left">
              <div class="headerLine"></div>
            </td>
            <td>
              <div class="headerLine3">
                <button
                  class="btn btnl1 btn-block btn-sm"
                  @click.stop="show = false"
                  type="submit"
                >
                  <span class="material-icons white1"> close </span>
                  Close
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <dialogConfirm ref="confirm"></dialogConfirm>

      <v-card-text style="height: 100%">
        <template v-if="isDone">
          <stopDone :stopId="stopId" :closeProp="show"></stopDone>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon @click="show2 = !show2">
              <v-icon>{{
                show2 ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show2">
              <editStop :stopId="stopId" :closeProp="show"></editStop>
            </div>
          </v-expand-transition>
        </template>

        <template v-else>
          <editStop :stopId="stopId" :closeProp="show"></editStop>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { EventBus } from "./event-bus";
export default {
  props: {
    value: Boolean,
    stopId: Number,
  },
  components: {},
  data() {
    return {
      showStopDataDialog: true,
      triggerC: 0,
      show2: false,
      doneAt: "08:12",
      deliveryStatus: 0, //"deliverted do ...",
      deliveryStatusAddtionalInfo: null, // "no parking  ...",
    };
  },
  computed: {
    isDone() {
      return this.deliveryStatus != 0;
    },
    isOptimized() {
      return this.$store.getters.getRouteData("is_optimized") == 1;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
  methods: {
    async deleteStop() {
      if (
        await this.$refs.confirm.openConfirm(
          "Delete",
          "Do you really want to delete the location?",
          {
            color: "blue",
          }
        )
      ) {
        // yes
        this.$store.dispatch("deleteStopStore", this.stopId);
        this.show = false;
      } else {
        // cancel
      }
    },
    duplicateStop() {
      this.$store.dispatch("duplicateStop", this.stopId).then(() => {
        this.$store.dispatch("getStops").then(() => {
          EventBus.$emit("LOAD_ROUTE_EVENT");
        });
      });
    },
    refreshData() {
      this.id = this.stopId; //this.$route.params.id;
      for (var i = 0; i < this.$store.getters.stops.length; i++) {
        if (this.$store.getters.stops[i].id == this.id) {
          this.deliveryStatus = this.$store.getters.stops[i].delivery_status;
          break;
        }
      }
    },
  },

  created() {},
  watch: {
    stopId() {
      this.refreshData();
    },
  },
};
</script>


<style scoped>
td {
  padding-left: 5px;
}

.row {
  margin-right: 0 !important;
}

.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}

.btnl1-ghost {
  color: #0b6f7a;
  background-color: transparent;
  border-color: rgba(63, 107, 190, 0.15);
}

.btnl1-ghost:hover {
  background-color: #0b6f7a;
  color: #ffffff !important;
}
</style>