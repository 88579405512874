export const state = {
    stops: [
        // { id: 1, name: "BMW Ulica", address: "Mała 75, Katowice", note:''},
        // { id: 2, name: "Google Aleja", address: "Sokolska 3, Simeionowice", note:'' },

    ]
};

export const getters = {
    stops: state => {
        return state.stops;
    }
};

export const mutations = {
    endDay: state => {
        state.stocks.forEach((item) => {
            item.price = Math.round(item.price * (1 + Math.random() - 0.5));
        });
    },
    // addStop (state, stop) {
    //     // mutate state
    //     state.stops.push(stop)
    // }
};

// export const actions = {
//     deleteStop (state, stopId) {
//         for (var i=0; i<state.stops.length; i++) {			
//             if (state.stops[i].id == stopId) {
//                 state.stops.splice(i, 1);
//                 
//                 break;
//             }
//         }
//     },
// };

export default {
    state,
    mutations,
    getters
}