export const state = {
    routes: [
    ]
};

export const getters = {
    routes: state => {
        return state.routes;
    }
};

export const mutations = {
};

export default {
    state,
    mutations,
    getters
}