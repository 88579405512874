<template>
  <article class="text-center mobile-center v-full-height splash">
    <section class="fw-section">
      <div class="container fullwidthsec">
        <div class="row">
          <div class="col-sm-3"></div>

          <div class="col-sm-6">
            <div class="page-title title-size-normal white1">
              <div class="container">
                <div class="inner">
                  <div class="column">
                    <center>
                      <div class="titlelogin">Reset password</div>
                    </center>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row padding-bottom-1x">
                <div class="col-xs-0 text-left"></div>
              </div>
              <label for="password" class="control-label">New Password</label>
              <input
                type="password"
                v-model="password"
                name="password"
                class="form-control"
                :class="{ 'is-invalid': submitted && !password }"
              />
              <div v-show="submitted && !password" class="invalid-feedback">
                Password is required
              </div>
            </div>

            <div class="form-group">
              <div class="row padding-bottom-1x">
                <div class="col-xs-0 text-left"></div>
              </div>
              <label for="password" class="control-label"
                >Confirm new Password</label
              >
              <input
                type="password"
                v-model="password2"
                name="password2"
                class="form-control"
                :class="{ 'is-invalid': submitted && !password }"
              />
              <div v-show="submitted && !password" class="invalid-feedback">
                Password is required
              </div>
            </div>

            <div class="form-group">
              <p class="subtitle error-msg">{{ errorMsg }}</p>
              <button
                class="btn btn-ghost btn-rounded btnl1 btn-block waves-effect waves-light"
                type="submit"
                @click="resetPassword()"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </section>
    <div class="padding-bottom-2x"></div>
    <router-link :to="{ name: 'Login' }"> Go back to Login</router-link>

    <v-dialog
      v-model="dialog"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>Forgot Password</v-toolbar>
        <v-card-text class="text-h5">
          <v-container fluid="true"> </v-container>
          Thanks! If there's an account associated with this email, we'll send
          the password reset instructions immediately.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            class="text-h6"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogConfirm ref="confirm"></dialogConfirm>
  </article>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      password2: "",
      dialog: false,
    };
  },
  computed: {},
  methods: {
    showInfo(message) {
      if (
        this.$refs.confirm.openInfo("Error", message, {
          color: "blue",
        })
      );
    },
    async showInfoSuccess() {
      if (
        await this.$refs.confirm.openInfo(
          "Success",
          "Password has been changed successfully",
          {
            color: "blue",
          }
        )
      ) {
        // yes
        this.$router.push({
          name: "Login",
        });
      }
    },
    resetPassword() {
      if (this.password == null || this.password.length == 0) {
        this.showInfo("The password can't be empty");
      }
      if (this.password != this.password2) {
        this.showInfo("The password and confirmation password do not match.");
      }
      if (
        this.$route.params.resetToken != null &&
        this.$route.params.resetToken.length > 3
      ) {
        this.$store
          .dispatch("resetPassword", {
            password: this.password,
            token: this.$route.params.resetToken,
          })
          .then((response) => {
            //this.processRoutes(response.routes);
            if (response.data.status == 0) {
              this.showInfoSuccess();
            } else {
              this.showInfo("Link is not valid or expired");
            }
          });

        /*this.$store
          .dispatch("resetPassword", {
            password: this.password,
            token: this.$route.params.resetToken,
          })
          .then(() => {});*/
      }
    },
  },
};
</script>



<style>
a {
  color: red;
}
.error-msg {
  color: red;
  font-weight: bold;
}
</style>

<style scoped>
.wlink a,
a:active,
a:hover {
  color: #ffffff !important;
}
.v-application a {
  color: #ffffff !important;
}

.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}

.fullwidthsec {
  max-width: 900px;
}
</style>