<template>
  <v-container fluid>
    <div>
      <search-results :query="query" />
      <div class="padding-bottom-2x">
        <autocomplete
          :search="search"
          :placeholder="hints"
          :get-result-value="getResultValue"
          @submit="handleSubmit"
          :debounce-time="500"
          ref="autocompleteRef"
          :disabled="isDisabled"
        >
          <template #result="{ result }">
            <i
              class="material-icons room text-default"
              style="font-size: 18px; padding-bottom: 5px; padding-right: 5px"
            ></i>
            <a @click="addStop(result)">
              {{ result.address.freeformAddress }}</a
            >
            <br />
          </template>
        </autocomplete>
      </div>
    </div>
  </v-container>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";
const searchApiUrl = "https://api.tomtom.com/search/2/search/";
const params2 = ".json?lat=37.337&lon=-121.89";
//const params3 = "key=tkaEbFIFqsNAANg3DOibqgfiedEUk3ha";
const params3 = "key=" + process.env.VUE_APP_TOMTOM_MAP_API_SERVER_KEY;
const params4 = "typeahead=true"; //&idxSet=Addr";

export default {
  props: ["hints", "isOptimized", "isDisabled"],
  components: {
    Autocomplete,
  },
  data: () => ({
    address: "",
    name: "",

    value: "",
    results: [],
    selectedIndex: 0,
    country: "",
  }),
  methods: {
    search(input) {
      this.value = input;
      const url = `${searchApiUrl}/${encodeURI(
        input
      )}${params2}&${params3}&${params4}`;

      return new Promise((resolve) => {
        if (input.length < 1) {
          return resolve([]);
        }

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            if (this.value) {
              resolve(data.results);
            } else {
              resolve([]);
            }
          });
      });
    },
    // We want to display the title
    getResultValue(result) {
      return result.address.freeformAddress;
    },

    // Open the selected article in
    // a new window
    handleSubmit(result) {},
    handleUpdate(results, selectedIndex) {
      this.results = results;
      this.selectedIndex = selectedIndex;
    },
    addStop(tomtomResult) {
      var nameAndAdress = this.getNameAndAddress(tomtomResult);
      var name = nameAndAdress[0];
      var address = nameAndAdress[1];
      var country = this.getCountry(tomtomResult);
      var fullAddress = this.getFullAddress(tomtomResult);
      this.$emit("found-address", {
        name: name,
        address: address,
        lat: tomtomResult.position.lat,
        lng: tomtomResult.position.lon,
        country: country,
        fullAddress: fullAddress,
      });
      this.clear();
    },
    getFullAddress(tomtomResult) {
      var name = "";
      if (
        tomtomResult.address != null &&
        tomtomResult.address.freeformAddress != null
      ) {
        return tomtomResult.address.freeformAddress;
      } else if (
        tomtomResult.type.localeCompare("POI") &&
        tomtomResult.poi != null &&
        tomtomResult.poi.name != null
      ) {
        return tomtomResult.poi.name;
      } else {
        name = "unknown";
      }
      return name;
    },
    getCountry(tomtomResult) {
      if (
        tomtomResult != null &&
        tomtomResult.address != null &&
        tomtomResult.address.countryCode != null
      ) {
        return tomtomResult.address.countryCode;
      }
      return "";
    },
    getDistanceUnit(countryCode) {
      if (countryCode != null && countryCode.length > 0) {
        if (countryCode.toUpperCase() === "US") {
          return 1;
        }
      }
      return 0;
    },
    getNameAndAddress(tomtomResult) {
      var name = "";
      var address = "";

      if (tomtomResult.type === "Geography") {
        name = tomtomResult.address.freeformAddress;
        address = tomtomResult.address.country;
      } else if (
        tomtomResult.type.localeCompare("POI") &&
        tomtomResult.poi != null &&
        tomtomResult.poi.name != null
      ) {
        name = tomtomResult.poi.name;
        address = tomtomResult.address.freeformAddress;
      } else if (tomtomResult.address.freeformAddress != undefined) {
        var k = tomtomResult.address.freeformAddress.indexOf(",");
        if (k >= 0) {
          name = tomtomResult.address.freeformAddress.substring(0, k).trim();
          address =
            tomtomResult.address.freeformAddress.substring(k + 1).trim() +
            ", " +
            tomtomResult.address.country;
        } else {
          if (tomtomResult.address.streetName) {
            name = tomtomResult.address.streetName;
          }
          if (tomtomResult.address.freeformAddress) {
            address = tomtomResult.address.freeformAddress;
          }
        }
      } else {
        name = "unknown";
      }
      return [name, address];
    },
    clear() {
      this.value = "";
      this.$refs.autocompleteRef.value = "";
      this.$refs.autocompleteRef.results = [];
    },

    onChange(event) {},
  },

  mounted() {},

  created() {},
};
</script>


