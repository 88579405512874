<template>
  <v-container fluid style="width: 100%; padding: 0">
    <article class="text-center1 mobile-center1 v-full-height splash">
      <section class="fw-section">
        <div class="container fullwidthsec">
          <div class="page-title title-size-normal text-light">
            <div class="col-xs-12 text-right">
              <router-link to="contact">Contact</router-link>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <div class="page-title title-size-normal text-dark">
                <div class="container white1">
                  <div class="inner white1">
                    <div class="column white1">
                      <center>
                        <div class="titlelogin">Log in to your account</div>
                      </center>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group padding-bottom-2x">
                <div class="row padding-bottom-1x">
                  <div class="col-xs-0 text-left"></div>
                  <div class="col-xs-12 text-right">
                    <router-link to="signup">
                      New here? Sign up for free.
                    </router-link>
                  </div>
                </div>
                <label for="email" class="control-label white1">Email</label>
                <input
                  type="text"
                  v-model="email"
                  name="email"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && !email }"
                  tabindex="110"
                />
                <div v-show="submitted && !email" class="invalid-feedback">
                  Email is required
                </div>
              </div>

              <div class="form-group">
                <div class="row padding-bottom-1x">
                  <div class="col-xs-0 text-left"></div>
                  <div class="col-xs-12 text-right">
                    <router-link to="forgot-password">
                      Forgot password?</router-link
                    >
                  </div>
                </div>
                <label for="password" class="control-label white1"
                  >Password</label
                >
                <input
                  type="password"
                  v-model="password"
                  name="password"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && !password }"
                />
                <div v-show="submitted && !password" class="invalid-feedback">
                  Password is required
                </div>
              </div>

              <div class="form-group">
                <p class="subtitle error-msg">{{ errorMsg }}</p>
                <button
                  class="btn btn-ghost1 btn-rounded btn-default btn-block waves-effect1 waves-light1 btnl1"
                  type="submit"
                  @click="login()"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </section>
    </article>
    <vue-cookies-consent
      backgroundButton="#015a66"
      borderRadius="5"
      backgroundButtonHover="#3A4F52"
      offsetY="5"
    >
      <template #description>
        <p>
          We use cookies to ensure you get the best experience on our website
          <a href="https://maposcope.com/privacy-policy/"
            ><span style="color: #0b6f7a">Read more</span></a
          >
        </p>
      </template>
      <template #button>Accept cookies</template>
    </vue-cookies-consent>
  </v-container>
</template>

<script>
import VueCookiesConsent from "@norvikit/vue-cookies-consent";
export default {
  components: {
    VueCookiesConsent,
  },
  data() {
    return {
      email: "",
      password: "",
      errorMsg: "",
    };
  },
  computed: {},
  methods: {
    login() {
      this.$store
        .dispatch("auth/login", { email: this.email, password: this.password })
        .then(
          (data) => {
            if (data.success == 1) {
              this.errorMsg = "";
              this.$router.push({
                name: "Routes",
              });
            }
          },
          (data) => {
            this.errorMsg = data["message"];
          }
        );
    },
  },
};
</script>


<style scoped>
a {
  color: red;
}
.error-msg {
  color: red;
  font-weight: bold;
}

.wlink a,
a:active,
a:hover {
  color: #ffffff !important;
}
.v-application a {
  color: #ffffff !important;
}

.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}

.fullwidthsec {
  max-width: 900px;
}
</style>