<template>
  <v-container
    fluid
    style="min-height: 0; padding-left: 40px; padding-right: 40px"
  >
    <article class="page text-left mobile-center padding-top-1x">
      <div>
        <div class="row padding-bottom-3x">
          <h4>Manage your Preferences</h4>
        </div>

        <label>Distance unit:</label>
        <v-combobox
          v-model="select"
          :items="distanceItems"
          @change="onChange($event)"
        ></v-combobox>
        <div class="row padding-bottom-1x"></div>
        <search-results :query="query" />

        <div style="color: #808080; display: inline">Start Point:</div>
        <strong> {{ startAddress }} </strong>
        <div class="padding-bottom-3x">
          <search
            hints="Find new start location"
            v-on:found-address="onSearchResults"
          ></search>
        </div>
      </div>
    </article>
  </v-container>
</template>

<script>
//import Search from "./SearchMaposcope.vue";
import Search from "./SearchTomTom.vue";

export default {
  components: {
    Search,
  },
  data: () => ({
    distanceItems: ["KM", "Miles"],
    startAddress: "",
    lat: "",
    lng: "",
    value: "",
    results: [],
    selectedIndex: 0,
  }),
  methods: {
    onSearchResults: function (params) {
      this.lat = params.lat;
      this.lng = params.lng;
      this.startAddress = params.fullAddress;
    },
    onChange(event) {
      if (this.distanceItems[0] == event) {
        localStorage.distanceUnit = 0;
      } else if (this.distanceItems[1] == event) {
        localStorage.distanceUnit = 1;
      }
    },
  },

  mounted() {
    if (localStorage.startAddress) {
      this.startAddress = localStorage.startAddress;
      this.lat = localStorage.startLat;
      this.lng = localStorage.startLng;
    }
    var k = 1;
    if (localStorage.distanceUnit) {
      k = localStorage.distanceUnit;
    } else {
      k = 1;
    }
    this.select = this.distanceItems[k];
  },
  watch: {
    startAddress(newStartAddress) {
      localStorage.startAddress = newStartAddress;
      localStorage.startLat = this.lat;
      localStorage.startLng = this.lng;
    },
  },

  created() {
    this.$store.dispatch("getPreferences").then((response) => {
      this.startAddress = response.data.preferences.start_address;
      this.lat = response.data.preferences.lat;
      this.lng = response.data.preferences.lng;
    });
  },
};
</script>
