<template>
  <div>
    <p v-if="isConnected">We're connected to the server!</p>
    <p>Message from server: "{{ socketMessage }}"</p>
    <button @click="pingServer()">Ping Server</button>

    <!-- <routePlannedDate /> -->

    <!-- <stopdata2 :stopId="stopId" v-model="showScheduleForm" /> -->

    <div class="text-center">
      yyyy

      {{ this.latitude }}
      {{ this.longitude }}

      getCountry={{ getCountry }}

      <button @click="showInfo()">Show confirm</button>
      <br /><br /><br /><br />
    </div>

    ddd
    <dialogConfirm ref="confirm"></dialogConfirm>

    <dialogInfo ref="dialoginfo"></dialogInfo>
    <dialogInfo
      :message="dialogInfo1_message"
      :title="dialogInfo1_title"
      v-model="dialogInfo1_showDialog"
    />
  </div>
</template>

<script>
import countyUtils from "@/country-utils.js";
//import MBOX from "./components/mbox.vue";
//import _Vue from "vue";
export default {
  //components: { VueTimepicker },
  data() {
    return {
      dialogInfo1_showDialog: false,
      dialogInfo1_message: "asdfasd asdfasdfasd",
      dialogInfo1_title: "1231231",
      isConnected: false,
      socketMessage: "",
      loading: false,
      loader: null,
      showScheduleForm: false, //true,
      dialog: false,
      timeValueSince: "",
      checkedProducts: [],
      products: [122, 333],
      items: [{ message: "Foo" }, { message: "Bar" }],
      productsBarcodeModel: "0102, 333, 555",
      productsBarcodeScannedModel: "123,333, 444,555, 234",
      latitude: null,
      longitude: null,
      rules: [
        // (value) => !!value || "Required.",
        //(value) => (value || "").length != 11 || "11 characters", //+XXXXXXXXXXX
        // (value) => {
        //   const pattern =
        //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //   return pattern.test(value) || "Invalid e-mail.";
        // },
        //(value) => (value || "").length == 0 || true, //+XXXXXXXXXXX
        (value) => {
          if (value.length == 0) {
            return true;
          }
          const pattern = /^\+[0-9]{11}$/;
          return pattern.test(value) || "Invalid phone";
        },
      ],
    };
  },
  computed: {
    getCountry() {
      return countyUtils.getCountry();
    },
    productBarcodesScanned() {
      var items2 = [];
      var predefinied = [];
      var scanned = [];
      var scannedAlreadyAdded = new Set();

      if (this.productsBarcodeModel) {
        predefinied = this.productsBarcodeModel.split(",");
      }

      if (this.productsBarcodeScannedModel) {
        scanned = this.productsBarcodeScannedModel.split(",");
      }

      for (var i = 0; i < predefinied.length; i++) {
        var isScanned = 0;
        for (var j = 0; j < scanned.length; j++) {
          if (predefinied[i].trim() == scanned[j].trim()) {
            isScanned = 1;
            scannedAlreadyAdded.add(j);
            break;
          }
        }
        items2.push({ message: predefinied[i].trim(), checked: isScanned });
      }

      for (var k = 0; k < scanned.length; k++) {
        if (!scannedAlreadyAdded.has(k)) {
          items2.push({ message: scanned[k].trim(), checked: 1 });
        }
      }

      return items2;
    },
  },
  sockets: {
    /* connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },*/

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    },
  },

  methods: {
    upgradePlan() {
      this.loader = "loading";
      // this.$store.dispatch("getCheckout").then((response) => {
      //   this.loader = null;
      //   this.loading = false;
      //   if (response.data.err == 0) {
      //     window.location.href = response.data.url;
      //   }
      // });
    },
    showDialog() {
      //this.dialogInfo1_showDialog = true;
      this.$refs.dialoginfo.open("info test", "Are you sure?", {
        color: "blue",
      });
    },
    async showInfo() {
      if (
        await this.$refs.confirm.openInfo("Info", "simple inform madsmads?", {
          color: "blue",
        })
      ) {
        // yes
      }
    },

    async showConfirm() {
      if (
        await this.$refs.confirm.openConfirm("Delete", "Are you sure?", {
          color: "blue",
        })
      ) {
        // yes
      } else {
        // cancel
      }
    },

    /*mbox(mText, mTitle, mBtnCap1, mBtnCap2, mBtnCap3) {
      return new Promise(async (resolve, reject) => {
        if (!mTitle) {
          mTitle = "My Title";
        }
        if (!mBtnCap1) {
          mBtnCap1 = "OK";
        }

        // I'm combining a bunch of stuff to make this work.
        // First, create the vue component
        var mboxInstance = _Vue.extend(MBOX); // mbox component, imported at top of Sublib
        var oComponent = new mboxInstance({
          propsData: {
            msg: mText,
            title: mTitle,
            btnCap1: mBtnCap1,
            btnCap2: mBtnCap2,
            btnCap3: mBtnCap3,
            retval: 0,
          },
        }).$mount();

        // now add it to the DOM
        var oEl = document.getElementById("app").appendChild(oComponent.$el);

        // NOTE: couldn't get it to work without adding a 'button' to activate it
        // progrmatically click it and make the button invisible
        // document.getElementById('mbox_btn_launch').click();
        var oLuanchBtn = document.getElementById("mbox_btn_launch");
        oLuanchBtn.style.visibility = "hidden";
        oLuanchBtn.click();

        // Add a listener so we can get the value and return it
        oComponent.$watch("retval", function (newVal, oldVal) {
          // this is triggered when they chose an option
          // Delete the component / element now that I'm done
          oEl.remove();
          resolve(Number(newVal));
        });
      }); // promise
    }, // mbox
    */
    cancel() {},
    accept() {},

    pingServer() {
      // Send the "pingServer" event to the server.
      this.$socket.emit("message", "PING!");
    },
    getInitialUserData() {
      //console.log(process.env.VUE_APP_API_SERVER);
      //console.log(process.env.VUE_APP_MAPOSCOPE_SEARCH_API_SERVER);
      //alert(process.env.VUE_APP_MAPOSCOPE_SEARCH_API_SERVER);
      let countryCode = countyUtils.getCountry();
      if (navigator.geolocation) {
        const success = (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;

          // Do something with the position
        };

        const error = (err) => {};

        // This will open permission popup
        navigator.geolocation.getCurrentPosition(success, error);
      }
    },
  },
  watch: {
    dialog(val) {
      if (!val) return;
      setTimeout(() => (this.dialog = false), 4000);
    },
  },
  created() {
    this.getInitialUserData();
  },
};
</script>

<style scoped>
/*v-btn {
  border-radius: 8px !important;
  border-color: red !important;
  color: red !important;
  background-color: red !important;
  text-decoration: none !important;
  max-width: auto;
  font-size-adjust: auto;
  margin: 1px;
}
*/
.v-btn__loader {
  color: red !important;
  background-color: red !important;
}
h1 {
  color: green;
}
.v-progress-circular {
  color: red !important;
  background-color: red !important;
}

.v-progress-circular--interminate {
  color: red !important;
  background-color: red !important;
}
</style>