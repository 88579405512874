<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 20px;
      padding-right: 20px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <!-- header -->
          <table class="table table-borderless" style="border: none !important">
            <tr>
              <td>
                <router-link to="" @click.native="save()"
                  ><span class="material-icons" style="font-size: 36px">
                    chevron_left
                  </span></router-link
                >
              </td>
              <td style="width: 80%; text-align: left">
                <div class="headerLine">Select drivers</div>
              </td>
              <td style="width: 10%; text-align: right; font-size: 24px"></td>
            </tr>
          </table>
          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
          <div class="row padding-bottom-1x"></div>

          <!-- header -->
          <table class="table table-borderless" style="border: none !important">
            <tbody>
              <tr v-for="(driver, i) in team" :key="i">
                <td scope="row" style="width: 5%; padding: 14px 10px 0px 10px">
                  <v-checkbox
                    v-model="selected"
                    :label="driver.email"
                    :value="driver.id"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="row padding-bottom-3x"></div>

          <div class="rowm">
            <div class="row" style="display: block; float: right">
              <button
                class="btn btnl1 btn-block"
                @click="newRoute()"
                type="submit"
              >
                Done
              </button>

              <div class="col-sm-3"></div>
              <div class="col-sm-3"></div>
            </div>
          </div>
          <dialogInfo
            :message="dialogInfo1_message"
            :title="dialogInfo1_title"
            v-model="dialogInfo1_showDialog"
          />
        </div>
      </section>
    </article>
  </v-container>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      team: [],
      selected: [],
      dialogInfo1_showDialog: false,
      dialogInfo1_message: "",
      dialogInfo1_title: null,
    };
  },
  methods: {
    save() {
      this.$store.dispatch("saveDrivers", this.selected).then(() => {
        this.$router.go(-1);
      });
    },
    showInfo(message) {
      this.dialogInfo1_showDialog = true;
      this.dialogInfo1_message = message;
      this.dialogInfo1_title = null;
    },
    newRoute() {
      if (this.selected == 0 && this.team.length > 0) {
        this.showInfo("Please select drivers first");
      } else {
        this.$store.dispatch("saveDrivers", this.selected).then(() => {
          this.$router.push({ name: "Stops" });
        });
      }
    },
  },

  created() {
    this.$store.dispatch("getDrivers").then((response) => {
      this.team = response.data.team;
      for (var i = 0; i < this.team.length; i++) {
        if (this.team[i].is_current_driver) {
          this.selected.push(this.team[i].id);
        }
      }
    });
  },
};
</script>

<style scoped>
table tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
  max-height: 500px;
}
.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}
</style>