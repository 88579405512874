<template>
  <article class="text-center1 mobile-center1 v-full-height splash">
    <section class="fw-section">
      <div class="container fullwidthsec">
        <div class="row">
          <div class="col-sm-3"></div>

          <div class="col-sm-6">
            <div class="page-title title-size-normal white1">
              <div class="container">
                <div class="inner">
                  <div class="column">
                    <center>
                      <div class="titlelogin">Forgot password</div>
                    </center>
                  </div>
                </div>
              </div>
              To receive a link to reset your password, please enter your email
              address.
            </div>

            <div class="form-group padding-bottom-2x">
              <div class="white1"><b>Email:</b></div>
              <input
                type="text"
                v-model="email"
                name="email"
                class="form-control"
                :class="{ 'is-invalid': submitted && !email }"
                tabindex="110"
              />
              <div v-show="submitted && !email" class="invalid-feedback">
                Email is required
              </div>
            </div>

            <div class="form-group">
              <p class="subtitle error-msg">{{ errorMsg }}</p>
              <button
                class="btn btn-ghost btn-rounded btnl1 btn-block waves-effect waves-light"
                type="submit"
                @click="forgotPassword()"
              >
                Request reset link
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>

        <div class="padding-bottom-2x"></div>
        <center><router-link to="login"> Go back to Login</router-link></center>
      </div>
    </section>

    <v-dialog
      v-model="dialog"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark>Forgot Password</v-toolbar>
        <v-card-text class="text-h5">
          <v-container fluid="true"> </v-container>
          Thanks! If there's an account associated with this email, we'll send
          the password reset instructions immediately.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            class="text-h6"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogConfirm ref="confirm"></dialogConfirm>
  </article>
</template>

<script>
export default {
  data() {
    return {
      email: "",

      dialog: false,
    };
  },
  computed: {},
  methods: {
    async showInfo() {
      if (
        await this.$refs.confirm.openInfo(
          "Forgot Password",
          "Thanks! If there's an account associated with this email, we'll send the password reset instructions immediately.",
          {
            color: "blue",
          }
        )
      ) {
        // yes
        this.$router.push({
          name: "Login",
        });
      }
    },
    forgotPassword() {
      if (this.email && this.email.length > 5) {
        this.showInfo();
        this.$store.dispatch("forgotPassword", this.email).then(() => {});
      }
    },
  },
};
</script>


<style>
a {
  color: red;
}
.error-msg {
  color: red;
  font-weight: bold;
}
</style>

<style scoped>
.wlink a,
a:active,
a:hover {
  color: #ffffff !important;
}
.v-application a {
  color: #ffffff !important;
}

.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}

.fullwidthsec {
  max-width: 900px;
}
</style>