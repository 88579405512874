<template>
  <v-container
    fluid
    style="padding-left: 0px; padding-right: 0px; background-color: #f3f3f3"
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <div></div>

          <div class="row">
            <div class="col-sm-8">
              <div class="team">{{ currentTeam }}</div>
              <div class="email">
                Hello {{ currentEmail }}, Welcome to your account
              </div>
            </div>
            <div class="col-sm-4 text-right">
              <nav class="topbar-menu">
                <v-menu open-on-hover bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <i
                        class="material-icons more_vert"
                        style="
                          font-size: 24px;
                          padding-bottom: 5px;
                          padding-right: 15px;
                          color: #002e36;
                        "
                      ></i>
                    </v-btn>
                  </template>

                  <v-list>
                    <ul class="menu">
                      <div id="menu-item">
                        <i
                          class="material-icons account_circle"
                          style="
                            font-size: 24px;
                            padding-bottom: 5px;
                            color: #002e36;
                          "
                        ></i>
                        <div
                          class="email"
                          style="display: inline; vertical-align: baseline"
                        >
                          {{ currentEmail }}
                        </div>
                      </div>
                    </ul>
                  </v-list>
                </v-menu>
              </nav>
            </div>
          </div>

          <div class="logout50">
            <button
              class="btn btnl1 btn-sm btn-block btn-ghost"
              @click="logout()"
              v-on:click.stop
              type="submit"
              style="margin-left: 25px; font-size: 14px"
            >
              <i
                class="material-icons person_outline text-default"
                style="font-size: 14px; padding-bottom: 5px; color: #ffffff"
              ></i>
              Logout
            </button>
          </div>
          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />

          <ul>
            <li>
              <i
                class="material-icons people text-default"
                style="
                  font-size: 18px;
                  padding-bottom: 5px;
                  padding-right: 5px;
                  padding-left: 10px;
                "
              ></i>
              <router-link :to="{ name: 'Team' }">Team members</router-link>
            </li>

            <li>
              <i
                class="material-icons account_balance text-default"
                style="
                  font-size: 18px;
                  padding-bottom: 5px;
                  padding-right: 5px;
                  padding-left: 10px;
                "
              ></i>
              <router-link :to="{ name: 'Subscription' }"
                >Manage your team subscription</router-link
              >
            </li>

            <li>
              <i
                class="material-icons settings text-default"
                style="
                  font-size: 18px;
                  padding-bottom: 5px;
                  padding-right: 5px;
                  padding-left: 10px;
                "
              ></i>
              <router-link :to="{ name: 'Preferences' }"
                >Route Setup</router-link
              >
            </li>
            <li>
              <i
                class="material-icons account_circle text-default"
                style="
                  font-size: 18px;
                  padding-bottom: 5px;
                  padding-right: 5px;
                  padding-left: 10px;
                "
              ></i>
              <router-link :to="{ name: 'profile' }"
                >Personal profile</router-link
              >
            </li>
          </ul>
        </div>
      </section>
    </article>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import countyUtils from "@/country-utils.js";

export default {
  data() {
    return {
      isLogged: false,
    };
  },
  methods: {
    openPreferences() {
      this.$router.push({ name: "Preferences" });
    },

    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
    saveInitialUserData() {
      var pref = {
        countryCode: localStorage.countryCode,
        latitude: localStorage.latitude,
        longitude: localStorage.longitude,
        timezone: localStorage.timezone,
      };
      this.$store.dispatch("saveTeamPreferences", pref).then(() => {});
    },
    getInitialUserData() {
      if (
        localStorage.getItem("latitude") === null ||
        localStorage.getItem("latitude").localeCompare("null") == 0
      ) {
        localStorage.timezone = countyUtils.getTimeZone();
        localStorage.countryCode = countyUtils.getCountry();
        if (navigator.geolocation) {
          const success = (position) => {
            localStorage.latitude = position.coords.latitude;
            localStorage.longitude = position.coords.longitude;

            this.saveInitialUserData();
            // Do something with the position
          };

          const error = (err) => {
            //this.saveInitialUserData(countryCode, null, null);
          };

          // This will open permission popup
          navigator.geolocation.getCurrentPosition(success, error);
        }
      }
    },
  },
  computed: {
    ...mapGetters("auth", {
      isAuth: "isAuthenticated",
    }),
    routes() {
      return this.$store.getters.routes;
    },
    routesCount() {
      return this.$store.getters.routes.length;
    },
    currentEmail() {
      return this.$store.state.auth.username;
    },
    currentTeam() {
      return this.$store.state.auth.teamname;
    },
  },
  created() {
    this.getInitialUserData();
  },
};
</script>

<style scoped>
/* ul li:hover {
  background-color: #f1f1f1;
  cursor: pointer;
} */

.lititle1 {
  color: #002e36;
  font-weight: bold;
  font-size: 14px;
}

#innerExPan {
  padding-top: 0px;
  padding-right: 00px;
  padding-bottom: 0px;
  padding-left: 00px;
}

ul li::before {
  content: "";
}

ul li {
  content: "";
}
.v-application ul,
.v-application ol {
  padding: 0px;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.email {
  color: #969696;
  font-size: 14px;
  margin-left: 10px;
}

.team {
  color: #002e36;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

v-list v-list-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

#menu-item {
  padding: 10px 20px 0px 20px;
}
.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}
</style>