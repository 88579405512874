<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 0px;
      padding-right: 0px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <table class="table table-borderless" style="border: none !important">
            <tr>
              <td>
                <router-link to="" @click.native="save()"
                  ><span class="material-icons" style="font-size: 36px">
                    chevron_left
                  </span></router-link
                >
              </td>
              <td style="width: 80%; text-align: left">
                <div class="headerLine">Settings</div>
              </td>
              <td style="width: 10%; text-align: right; font-size: 24px"></td>
            </tr>
          </table>
          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
        </div>
      </section>
      <section class="fw-section">
        <div class="container">
          <ul></ul>
        </div>
      </section>
    </article>
  </v-container>
</template>

<script>
export default {
  methods: {
    save() {
      var pref = {
        distanceUnit: localStorage.distanceUnit,
        name: localStorage.name,
        startAddress: localStorage.startAddress,
        startLat: localStorage.startLat,
        startLng: localStorage.startLng,
      };
      this.$store.dispatch("savePreferences", pref).then(() => {
        this.$router.push({ name: "Routes" });
      });

      this.$router.push({ name: "Routes" });
      for (var i = 0; i < this.$store.getters.stops.length; i++) {
        if (this.$store.getters.stops[i].id == this.id) {
          var stop = this.$store.getters.stops[i];
          stop.note = this.noteModel;
          this.$store.dispatch("editStop", stop).then(() => {
            this.$router.go(-1);
          });
        }
      }
    },
  },
};
</script>

<style scoped>
ul li::before {
  content: "";
}
</style>
