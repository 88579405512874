<template>
  <v-dialog
    scrollable
    v-model="show"
    :max-width="options.width"
    style="z-index: 1; background-color: #f3f3f3"
  >
    <v-card style="background-color: #f3f3f3">
      <v-toolbar color="#015a66" dark>
        <div class="title2">
          Setup start time, start and end location
        </div></v-toolbar
      >
      <div class="message2">
        Set when and where you want to start and where to end your route. For
        optimization to be performed, the start and end points cannot be empty.
      </div>

      <div
        style="
          font-size: 16px;
          padding-left: 16px;
          padding-right: 5px;
          padding-top: 10px;
        "
      >
        <label for="name">Start time:</label>
      </div>
      <div style="font-size: 14px; padding-left: 20px; padding-right: 5px">
        <vue-timepicker
          v-model="timeValueStartTime"
          format="HH:mm"
          @change="changeHandlerStartTime"
          :disabled="isOptimized"
        ></vue-timepicker>
      </div>
      <br /><br />

      <div
        style="
          font-size: 14px;
          padding-left: 16px;
          padding-right: 5px;
          padding-top: 20px;
        "
      >
        <label>Start Point:</label> {{ startAddress }}
      </div>

      <search
        hints="Find new start location"
        v-on:found-address="onSearchResultsStartLocation"
        :isDisabled="isOptimized"
      ></search>

      <div style="font-size: 14px; padding-left: 16px; padding-right: 5px">
        <label>End Point:</label> {{ endAddress }}
      </div>
      <div>
        <search
          hints="Find new end location"
          v-on:found-address="onSearchResultsEndLocation"
          :isDisabled="isOptimized"
        ></search>
      </div>

      <v-card-text></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="show = false"
          ><div class="button2">Cancel</div></v-btn
        >

        <v-btn color="primary" text @click="save()"
          ><div class="button2">OK</div></v-btn
        >
      </v-card-actions>
    </v-card>

    <dialogInfo
      :message="dialogInfo1_message"
      :title="dialogInfo1_title"
      v-model="dialogInfo1_showDialog"
    />
    <dialogConfirm ref="confirm"></dialogConfirm>
  </v-dialog>
</template>


<script>
import VueTimepicker from "vue2-timepicker";

export default {
  components: { VueTimepicker },
  props: {
    value: Boolean,
    message: String,
    title: String,
  },
  data() {
    return {
      id: 0,
      nameModel: "not set",
      noteModel: "",

      timeValueStartTime: "",

      startAddress: "",
      endAddress: "",
      showDialog1: false,
      selectedDate: null,
      dialogInfo1_showDialog: false,
      dialogInfo1_message: "ddd",
      dialogInfo1_title: null,
      is_driver_route: false,
      options: {
        color: "#234233",
        width: 620,
        zIndex: 400,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    changeHandlerStartTime(eventData) {
      if (!eventData.data.HH) {
        this.timeValueStartTime = "08:" + eventData.data.mm;
      }
      if (!eventData.data.mm) {
        this.timeValueStartTime = eventData.data.HH + ":00";
      }
    },
    showInfo(message) {
      this.dialogInfo1_showDialog = true;
      this.dialogInfo1_message = message;
      this.dialogInfo1_title = null;
    },
    onSearchResultsStartLocation: function (params) {
      this.startAddressLat = params.lat;
      this.startAddressLng = params.lng;
      this.startAddress = params.fullAddress;
    },
    onSearchResultsEndLocation: function (params) {
      this.endAddressLat = params.lat;
      this.endAddressLng = params.lng;
      this.endAddress = params.fullAddress;
    },
    save() {
      var payload = {
        route_id: this.$store.getters.getRouteId,
        name: this.nameModel,
        desc: this.noteModel,
        planned_on: this.selectedDate,
      };
      if (this.timeValueStartTime) {
        var t = this.timeValueStartTime.split(":");
        var mins = parseInt(t[0]) * 60 + parseInt(t[1]);
        payload.start_time = mins; //e.g. 08:23
      }

      //if (this.startAddress != null && this.startAddress.length > 0) {
      payload.start_point_address = this.startAddress;
      payload.start_point_lat = this.startAddressLat;
      payload.start_point_lng = this.startAddressLng;
      //}
      //if (this.endAddress != null && this.endAddress.length > 0) {
      payload.end_point_address = this.endAddress;
      payload.end_point_lat = this.endAddressLat;
      payload.end_point_lng = this.endAddressLng;
      //}
      this.$store.dispatch("editRoute", payload).then(() => {
        this.show = false;
      });
    },
  },
  //created() {},
  created() {
    this.id = this.$route.params.id;
    this.$store.dispatch("getRoute").then((response) => {
      this.nameModel = response.route.name;
      this.noteModel = response.route.desc;
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      this.timeValueStartTime =
        zeroPad(parseInt(response.route.start_time / 60), 2) +
        ":" +
        zeroPad(response.route.start_time % 60, 2);

      this.startAddress = response.route.start_point_address;
      this.startAddressLat = response.route.start_point_lat;
      this.startAddressLng = response.route.start_point_lng;
      this.endAddress = response.route.end_point_address;
      this.endAddressLat = response.route.end_point_lat;
      this.endAddressLng = response.route.end_point_lng;
      this.selectedDate = this.$store.getters.getRouteData("planned_on");
      this.is_driver_route = response.route.is_driver_route == 1;
    });
  },
};
</script>


<style scoped>
/*.message {
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
  padding-bottom: 10px;
  font-size: 14px;
  padding: 20px;
}*/
</style>
