<template>
  <article class="text-center1 mobile-center1 v-full-height splash">
    <div class="page-title title-size-normal text-dark">
      <div class="container">
        <div class="inner">
          <div class="column white1">
            <center><div class="titlelogin">Sign Up</div></center>
          </div>
        </div>
      </div>
    </div>

    <div class="container text-center padding-bottom-4x fullwidthsec">
      <br /><br />

      <section class="fw-section">
        <div class="container">
          <div class="row padding-bottom-1x">
            <div class="col-xs-3 text-left"></div>
            <div class="col-xs-6 text-right">
              <router-link to="login">
                Already registered? Sign in.</router-link
              >
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <input id="next" name="next" type="hidden" value="/" />
              <input id="reg_next" name="reg_next" type="hidden" value="/" />
              <input
                id="invite_token"
                name="invite_token"
                type="hidden"
                value=""
              />

              <label for="email" class="control-label white1">Email</label>

              <input
                class="form-control"
                id="email"
                v-model="email"
                required
                tabindex="210"
                type="text"
                value=""
              />

              <label for="password" class="control-label white1"
                >Password</label
              >

              <input
                class="form-control"
                id="password"
                required
                tabindex="230"
                type="password"
                value=""
                v-model="password"
              />

              <p class="subtitle error-msg">{{ errorMsg }}</p>

              <p>
                <button
                  class="
                    btn btn-ghost btn-rounded
                    btnl1
                    btn-block
                    waves-effect waves-light
                  "
                  type="submit"
                  @click="register()"
                >
                  Create account
                </button>
              </p>

              <p class="white1">
                By signing up I agree to the
                <a href="https://maposcope.com/legal/">Terms and Conditions</a>
              </p>
            </div>

            <div class="col-sm-3"></div>
          </div>
        </div>
      </section>
    </div>

    <div class="container text-center padding-bottom-10x"></div>
    <div class="container text-center padding-bottom-10x"></div>
    <div class="container text-center padding-bottom-10x"></div>
    <vue-cookies-consent
      backgroundButton="#015a66"
      borderRadius="5"
      backgroundButtonHover="#3A4F52"
      offsetY="5"
    >
      <template #description>
        <p>
          We use cookies to ensure you get the best experience on our website
          <a href="https://maposcope.com/privacy-policy/"
            ><span style="color: #0b6f7a">Read more</span></a
          >
        </p></template
      >

      <template #button>Accept cookies</template>
    </vue-cookies-consent>
  </article>
</template>

<script>
import VueCookiesConsent from "@norvikit/vue-cookies-consent";
export default {
  components: {
    VueCookiesConsent,
  },
  data() {
    return {
      email: "",
      password: "",
      errorMsg: "",
    };
  },
  computed: {},
  methods: {
    register() {
      this.$store
        .dispatch("auth/register", {
          email: this.email,
          password: this.password,
        })
        .then(
          (data) => {
            if (data.success == 1) {
              this.errorMsg = "";
              this.$router.push({
                name: "OnboardingTeam",
              });
            }
          },
          (data) => {
            if (data["message"] != null) {
              this.errorMsg = data["message"];
            } else {
              this.errorMsg = "Error code " + data["err"];
            }
          }
        );
    },
  },
};
</script>

<style lang="scss">
.error-msg {
  color: red;
  font-weight: bold;
}
</style>

<style scoped>
.wlink a,
a:active,
a:hover {
  color: #ffffff !important;
}
.v-application a {
  color: #ffffff !important;
}

.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}

.fullwidthsec {
  max-width: 900px;
}
</style>