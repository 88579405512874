<template>
  <v-dialog
    hide-overlay
    v-model="showStopDataDialog"
    max-width="420"
    style="z-index: 1; background-color: #f3f3f3"
  >
    <v-card
      class="mx-auto"
      max-width="420"
      style="position: absolute; top: 120px; left: 440px"
    >
      <div class="rowm">
        <div class="stop_name">{{ nameModel }}</div>
        <div class="stop_address">{{ addressModel }}</div>
      </div>

      <div class="row padding-bottom-2x"></div>
      <div class="rowm">
        <label for="note" class="label_row"
          ><i class="material-icons description icon-blue"></i
          ><span class="text">Note</span></label
        >
        <div>{{ noteModel }}</div>
      </div>
      <hr
        class="hr-solid hr-primary"
        style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
      />

      <div class="rowm">
        <label for="note" class="label_row"
          ><i class="material-icons timer icon-blue"></i
          ><span class="text">Delivery time:</span></label
        >
        <div>{{ doneAt }}</div>
      </div>
      <div class="rowm">
        <label for="note" class="label_row"
          ><i class="material-icons local_shipping icon-blue"></i
          ><span class="text">Delivery status:</span></label
        >
        <div>{{ deliveryStatus }}</div>
        <div>{{ deliveryStatusAddtionalInfo }}</div>
      </div>
      <hr
        class="hr-solid hr-primary"
        style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
      />
      <v-img :src="photoModel" height="150px"></v-img>

      <v-card-title> 123 Top western road trips </v-card-title>

      <v-card-subtitle> 1,000 miles of wonder </v-card-subtitle>

      <v-card-actions>
        <v-btn color="orange lighten-2" text> Explore </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>

          <v-card-text>
            I'm a thing. But, like most politicians, he promised more than he
            could deliver. You won't have time for sleeping, soldier, not with
            all the bed making you'll be doing. Then we'll go with that data
            file! Hey, you add a one and two zeros to that or we walk! You're
            going to do his laundry? I've got to find a way to escape.
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  props: ["title"],
  components: {},
  data() {
    return {
      showStopDataDialog: true,
      show: false,

      nameModel: "not set",
      addressModel: "not set",
      noteModel: "Some short note",
      photoModel: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",

      doneAt: "08:12",
      deliveryStatus: "deliverted do ...",
      deliveryStatusAddtionalInfo: "no parking  ...",
    };
  },
  mounted() {},
  methods: {},

  created() {},
};
</script>
