<template>
  <v-container fluid>
    <div>
      <search-results :query="query" />
      <div class="padding-bottom-2x">
        <autocomplete
          :search="search"
          :placeholder="hints"
          @submit="handleSubmit"
          :debounce-time="500"
          ref="autocompleteRef"
          :disabled="isDisabled"
        >
          <template #result="{ result }">
            <i
              class="material-icons room text-default"
              style="font-size: 18px; padding-bottom: 5px; padding-right: 5px"
            ></i>
            <!-- <a @click="addStop(result)">
              {{ result.address.freeformAddress }}</a
            > -->

            <a @click="addStop(result)"> {{ result.properties.label }}</a>
            <br />
          </template>
        </autocomplete>
      </div>
    </div>
  </v-container>
</template>

<script>
import Autocomplete from "@trevoreyre/autocomplete-vue";

const searchApiUrl = process.env.VUE_APP_MAPOSCOPE_SEARCH_API_SERVER;
const paramsK =
  "key=" + process.env.VUE_APP_MAPOSCOPE_SEARCH_MAP_API_SERVER_KEY;

export default {
  props: ["hints", "isOptimized", "isDisabled"],
  components: {
    Autocomplete,
  },
  data: () => ({
    address: "",
    name: "",
    value: "",
    results: [],
    selectedIndex: 0,
    country: "",
  }),
  methods: {
    search(input) {
      this.value = input;
      var url = `${searchApiUrl}`;
      var lang = navigator.language || navigator.userLanguage;
      url += "lang=" + lang;
      if (
        localStorage.getItem("countryCode") !== null &&
        localStorage.getItem("countryCode").length == 2
      ) {
        //  url += "&boundary.country=" + localStorage.getItem("countryCode");
      }
      url += "&" + paramsK;
      if (
        localStorage.getItem("latitude") !== null &&
        localStorage.getItem("latitude").localeCompare("null") != 0 &&
        localStorage.getItem("latitude").length > 4 &&
        localStorage.getItem("longitude") !== null &&
        localStorage.getItem("longitude").localeCompare("null") != 0 &&
        localStorage.getItem("longitude").length > 4
      ) {
        url += "&focus.point.lat=" + localStorage.getItem("latitude");
        url += "&focus.point.lon=" + localStorage.getItem("longitude");
      }
      url += "&text=";
      url += `${encodeURI(input)}`;
      return new Promise((resolve) => {
        if (input.length < 3) {
          return resolve([]);
        }

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            resolve(data.features);
          });
      });
    },
    // We want to display the title
    getResultValue: (result) => result.title,

    // Open the selected article in
    // a new window
    handleSubmit(result) {},
    handleUpdate(results, selectedIndex) {
      this.results = results;
      this.selectedIndex = selectedIndex;
    },
    addStop(result) {
      var name = result.properties.name;
      var address = result.properties.label;
      var country = result.properties.country;
      var fullAddress = result.properties.label;
      this.$emit("found-address", {
        name: name,
        address: address,
        lat: result.geometry.coordinates[1],
        lng: result.geometry.coordinates[0],
        country: country,
        fullAddress: fullAddress,
      });
      this.clear();
    },

    clear() {
      this.value = "";
      this.$refs.autocompleteRef.value = "";
      this.$refs.autocompleteRef.results = [];
    },

    onChange(event) {},
  },
  mounted() {},
  created() {},
};
</script>


