<template>
  <div class="text-center">
    <v-dialog v-model="show" persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          <center>
            <div class="title">Route optimization</div>
            <br />
            <div class="messages">{{ message }}</div>
          </center>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
var array = [
  "Analysing route and stops ...",
  "Road traffic analysis ...",
  "Finding the best address order ...",
];

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      message: array[0],
      messageIdx: 0,
    };
  },
  methods: {
    changeText() {
      // do whatever you like here
      let d = Date();
      this.message = d;
      this.messageIdx += 1;
      this.messageIdx %= array.length;
      this.message = array[this.messageIdx];
      setTimeout(this.changeText, 1000);
    },
  },
  watch: {
    dialog(val) {
      if (!val) return;

      this.changeText();
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>
.title {
  display: inline-block;
  vertical-align: middle;
  line-height: 22px; /* <-- adjust this */
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  color: #ffffff;
}

.messages {
  display: inline-block;
  vertical-align: middle;
  line-height: 18px; /* <-- adjust this */
  padding-bottom: 10px;
  font-size: 12px;
  padding: 10px 5px 10px 5px;
  color: #ffffff;
}
</style>