<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 0px;
      padding-right: 0px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <div>
            <div class="stop_name">{{ nameModel }}</div>
            <div class="stop_address">{{ addressModel }}</div>
          </div>

          <div class="row padding-bottom-2x"></div>
          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />

          <div>
            <i class="material-icons timer icon-blue"></i>
            <div class="text2">Delivery time:</div>
            <div class="text3">
              {{ getTimeInFormatHHmm(stop.delivered_at) }}
            </div>
          </div>

          <div style="padding-top: 10px">
            <i
              v-if="stop.delivery_status == 1"
              class="material-icons done text-default"
              style="font-size: 20px; color: #136ef7"
            ></i>
            <i
              v-if="stop.delivery_status == 2"
              class="material-icons highlight_off text-default"
              style="font-size: 20px; color: #d80d0d"
            ></i>
            <div class="text2">
              {{ getDeliveryStatus }}
            </div>
            <br />
          </div>
          <div class="text4">{{ getDeliveryStatusAddtionalInfo }}</div>
          <div class="row padding-bottom-2x"></div>

          <div v-if="hasDeliveryNote">
            <i class="material-icons description icon-blue"></i>
            <div class="text2">Delivery note:</div>
            <div class="textNote">{{ deliveryNote }}</div>
          </div>

          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
          <template v-if="photoModel">
            <label for="note" class="label_row"
              ><i class="material-icons photo icon-blue"></i
              ><span class="text">Photo</span></label
            >
            <v-img :src="photoModel" contain height="400"></v-img>
          </template>
          <br />

          <template v-if="signatureModel">
            <label for="note" class="label_row"
              ><i class="material-icons edit icon-blue"></i
              ><span class="text">Signature</span></label
            >
            <v-img :src="signatureModel" height="150px"></v-img>
          </template>
        </div>
      </section>
    </article>
  </v-container>
</template>


<script>
export default {
  props: ["stopId"],
  components: {},
  data() {
    return {
      showStopDataDialog: true,
      show: false,

      nameModel: "not set",
      addressModel: "not set",
      noteModel: "Some short note",
      photoModel: "", //"https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
      signatureModel: "", //"https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",

      doneAt: "08:12",
      deliveryStatus: 1,
      deliveryStatusAddtionalInfo: 0,
      deliveryNote: "",

      hasDeliveryNote: null,
      stop: null,
    };
  },
  mounted() {
    this.refreshData();
  },
  computed: {
    getDeliveryStatus() {
      if (this.deliveryStatus == 1) {
        return "Delivered";
      } else if (this.deliveryStatus == 2) {
        return "Delivery failed";
      } else {
        return "-";
      }
    },
    getDeliveryStatusAddtionalInfo() {
      if (this.deliveryStatusAddtionalInfo == 1) {
        return "Recipient not home";
      } else if (this.deliveryStatusAddtionalInfo == 2) {
        return "Can't find address";
      } else if (this.deliveryStatusAddtionalInfo == 4) {
        return "No time";
      } else if (this.deliveryStatusAddtionalInfo == 3) {
        return "No parking";
      } else if (this.deliveryStatusAddtionalInfo == 5) {
        return "Other";
      } else {
        return "";
      }
      /* #POD_FAIL_RECIPIENT_NOT_HOME(1), POD_FAIL_CANT_FIND_ADDRESS(2),
      #POD_FAIL_NO_TIME(4), POD_FAIL_NO_PARKING(3),
      #POD_FAIL_OTHER(5);
      */
    },
  },
  methods: {
    refreshData() {
      this.id = this.stopId; //this.$route.params.id;
      for (var i = 0; i < this.$store.getters.stops.length; i++) {
        if (this.$store.getters.stops[i].id == this.id) {
          this.stop = this.$store.getters.stops[i];
          //setup some usefull data
          if (
            this.stop.proof_of_delivery_note &&
            this.stop.proof_of_delivery_note.length > 0
          ) {
            this.hasDeliveryNote = true;
          } else {
            this.hasDeliveryNote = false;
          }

          this.deliveryStatus = this.$store.getters.stops[i].delivery_status;
          this.deliveryStatusAddtionalInfo =
            this.$store.getters.stops[i].delivery_status_additional_info;

          this.nameModel = this.$store.getters.stops[i].name;
          this.addressModel = this.$store.getters.stops[i].address;
          this.recipeintNameModeldeliveryStatusAddtionalInfo =
            this.$store.getters.stops[i].delivery_status_additional_info;
          this.deliveryNote =
            this.$store.getters.stops[i].proof_of_delivery_note;

          const zeroPad = (num, places) => String(num).padStart(places, "0");
          if (
            this.$store.getters.stops[i].time_window_start &&
            this.$store.getters.stops[i].time_window_start > 0
          ) {
            let k = this.$store.getters.stops[i].time_window_start;
            this.timeValueSince =
              zeroPad(parseInt(k / 60), 2) + ":" + zeroPad(k % 60, 2);
          }

          if (
            this.$store.getters.stops[i].time_window_end &&
            this.$store.getters.stops[i].time_window_end > 0
          ) {
            let k = this.$store.getters.stops[i].time_window_end;
            this.timeValueTo =
              zeroPad(parseInt(k / 60), 2) + ":" + zeroPad(k % 60, 2);
          }

          //EventBus.$emit("SHOW_MARKER_EVENT", this.id);
          break;
        }
      }
      this.loadImages();
    },
    loadImages() {
      this.$store.dispatch("getPhotosStop", this.stopId).then((response) => {
        this.photoModel = response.photo;
        this.signatureModel = response.signature;
      });
    },
    getTimeInFormatHHmm: function (minutes) {
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      var h = zeroPad(parseInt(minutes / 60), 2);
      var m = zeroPad(minutes % 60, 2);

      return `${h}:${m}`;
    },
  },

  created() {},
  watch: {
    stopId() {
      this.refreshData();
    },
  },
};
</script>

<style  scoped>
.text3 {
  display: inline-block;
  vertical-align: middle;
  line-height: 18px; /* <-- adjust this */
  padding: 5px 0 0 10px;
  font-size: 14px;
}

.text4 {
  vertical-align: middle;
  line-height: 14px; /* <-- adjust this */
  padding: 5px 0 0 30px;
  font-size: 13px;
}

.textNote {
  vertical-align: middle;
  line-height: 14px; /* <-- adjust this */
  padding: 5px 0 0 30px;
  font-size: 13px;
}
</style>