<template>
  <v-container
    fluid
    style="min-height: 0; padding-left: 40px; padding-right: 40px"
  >
    <article class="page text-left mobile-center padding-top-1x">
      <div>
        <center>
          <h4>
            <i
              class="material-icons check_circle"
              style="font-size: 28px; padding: 0 5px 10px; color: #46ab57"
            ></i
            >Admin routes
          </h4>
        </center>
        <div class="row padding-bottom-1x"></div>
        <center>
          <v-date-picker
            v-model="selectedDate"
            @dayclick="onDayClick"
            v-on:change="onDate"
            no-title
            width="350"
            :events="routeDates"
          />
        </center>
      </div>
      <v-data-table
        :headers="headers"
        :items="routes"
        :items-per-page="20"
        class="elevation-3"
        style="font-size: 28px"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="openRoute(item)">
            play_circle_outline
          </v-icon>
        </template>
      </v-data-table>
    </article>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    currentPlan: "Pro", //Basic (Trial) / Pro
    name: "",
    loader: null,
    loading: false,
    selectedDate: null,
    routes: [],
    headers: [
      { text: "id", value: "id" },
      {
        text: "name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "planned_on", value: "planned_on" },
      { text: "is_optimized", value: "is_optimized" },
      { text: "created_at", value: "created_at" },
      { text: "team_id", value: "team_id" },
      { text: "drivers", value: "drivers" },

      { text: "Open", value: "actions", sortable: false },
    ],
  }),
  methods: {
    onDate() {
      if (this.selectedDate != null) {
        this.$store
          .dispatch("getAllRoutes", this.selectedDate)
          .then((response) => {
            this.processRoutes(response.routes);
          });
      }
    },
    openRouteInternal(route_id) {
      this.$store.commit("setCurrentRoute", route_id);
      for (var i = 0; i < this.$store.getters.routes.length; i++) {
        if (this.$store.getters.routes[i].id == route_id) {
          this.$store.commit(
            "setCurrentRouteName",
            this.$store.getters.routes[i].name
          );
          break;
        }
      }
      this.$router.push({ name: "Stops" });
    },
    openRoute(item) {
      this.openRouteInternal(item.id);
    },
    processRoutes(routes) {
      this.routes = routes;
    },
  },
  watch: {
    name(newName) {
      localStorage.name = newName;
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },
  mounted() {
    this.$store.dispatch("getAllRoutes").then((response) => {
      //
      this.processRoutes(response.routes);
    });
    this.pingServerUserLoggedIn();
  },
};
</script>
<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
  color: #ffffff;
  background-color: red;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

table.v-table thead th {
  font-size: 14px !important;
}
table.v-table tbody td {
  font-size: 14px !important;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
}
.v-data-table::v-deep td {
  font-size: 12px !important;
}
</style>

