<template>
  <div>
    <v-container fluid ma-0 pa-0 fill-height>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
      >
      </loading>
      <div id="mapContainer"></div>
      <!-- <v-dialog
        hide-overlay
        v-model="showDialog1"
        max-width="420"
        style="z-index: 1"
      >
        <v-card
          class="mx-auto"
          max-width="420"
          style="position: absolute; top: 120px; left: 440px"
        >
          <v-img
            src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
            height="200px"
          ></v-img>

          <v-card-title> Top western road trips </v-card-title>

          <v-card-subtitle> 1,000 miles of wonder </v-card-subtitle>

          <v-card-actions>
            <v-btn color="orange lighten-2" text> Explore </v-btn>

            <v-spacer></v-spacer>

            <v-btn icon @click="show = !show">
              <v-icon>{{
                show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text>
                I'm a thing. But, like most politicians, he promised more than
                he could deliver. You won't have time for sleeping, soldier, not
                with all the bed making you'll be doing. Then we'll go with that
                data file! Hey, you add a one and two zeros to that or we walk!
                You're going to do his laundry? I've got to find a way to
                escape.
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-dialog> -->
    </v-container>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { EventBus } from "./event-bus";
import "polyline-encoded";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

var MY_API_KEY = process.env.VUE_APP_TOMTOM_MAP_API_SERVER_KEY; //"tkaEbFIFqsNAANg3DOibqgfiedEUk3ha";

var polyUtil = require("polyline-encoded");

export default {
  name: "LeafletMap",
  components: {
    Loading,
  },
  data() {
    return {
      showDialog1: true,
      map: null,
      center: [50.251301, 19.038679],
      value: 0,
      markerArray: [],
      pathArray: [],
      markers: new L.FeatureGroup(),
      startPointMarker: null,
      endPointMarker: null,
      isLoading: false,
      isOptimized: false,
      isDriverRoute: false,
      drivers: {},
    };
  },
  /*sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;

      let token = localStorage.getItem("token");
      this.$socket.emit("userLoggedIn", token);
      this.socketMessage = token;
    },

    disconnect() {
      this.isConnected = false;
    },

    messageChannelDriverPositionChanged(data) {
      //this.socketMessage = new Date(); //data;
      let routeId = data["data"];

      if (routeId == this.$store.getters.getRouteId) {
        this.getDriversPostions();
      }
      //this.stopChanged(data);
      
    },
  },*/
  computed: {
    stopsCount() {
      return this.$store.getters.stops.length;
    },

    stops() {
      return this.$store.getters.stops;
    },
  },
  methods: {
    setupLeafletMap: function () {
      this.map = L.map("mapContainer").setView(this.center, 12);
      /*L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(this.map);*/

      L.tileLayer(
        "https://{s}.api.tomtom.com/map/1/tile/basic/{style}/{z}/{x}/{y}.{ext}?key={apikey}",
        {
          maxZoom: 22,
          attribution:
            '<a href="https://api.tomtom.com/map/2/copyrights?key=tkaEbFIFqsNAANg3DOibqgfiedEUk3ha" target="_blank">&copy;  1992 - 2022 TomTom.</a> ',
          subdomains: "abcd",
          style: "main",
          ext: "png",
          apikey: MY_API_KEY,
        }
      ).addTo(this.map);

      this.map.addLayer(this.markers);
    },
    addMarker2: function (stopId) {
      for (var i = 0; i < this.$store.getters.stops.length; i++) {
        if (this.$store.getters.stops[i].id == stopId) {
          var stop = this.$store.getters.stops[i];
          this.addMarker(stop);
          var loc = [stop.lat, stop.lng];
          this.map.setView(loc, 16);
          break;
        }
      }
    },
    loadRoute: function () {
      if (this.$store.getters.getRouteId != -1) {
        this.loadDepartures();
        this.getDriversPostions();
      } else {
        this.clearStops();
      }
    },
    loadDepartures: function () {
      this.$store.dispatch("getRoute").then((response) => {
        //

        var route = response.route;
        var startIcon = L.icon({
          iconUrl: require("@/assets/img2/home.png"),
          iconSize: [40, 40],
        });

        this.isDriverRoute =
          this.$store.getters.getRouteData("is_driver_route") == 1;
        this.isOptimized = route.is_optimized;
        if (route.start_point_lat && route.start_point_lng) {
          var startLoc = [route.start_point_lat, route.start_point_lng];
          this.startPointMarker = L.marker(startLoc, { icon: startIcon }).addTo(
            this.map
          );

          var isStartStopTheSame =
            route.start_point_lat == route.end_point_lat &&
            route.start_point_lng == route.end_point_lng;

          if (isStartStopTheSame) {
            this.startPointMarker.bindPopup(
              `<b>Start & Stop</b><br>` + route.start_point_address
            );
          } else {
            this.startPointMarker.bindPopup(
              `<b>Start</b><br>` + route.start_point_address
            );
          }
        }

        var stopIcon = L.icon({
          iconUrl: require("@/assets/img2/flag.png"),
          iconSize: [20, 20],
        });
        if (route.end_point_lat && route.end_point_lng) {
          var endLoc = [route.end_point_lat, route.end_point_lng];
          this.endPointMarker = L.marker(endLoc, { icon: stopIcon }).addTo(
            this.map
          );

          if (isStartStopTheSame) {
            this.endPointMarker.bindPopup(
              `<b>Start & Stop</b><br>` + route.end_point_address
            );
          } else {
            this.endPointMarker.bindPopup(
              `<b>Stop</b><br>` + route.end_point_address
            );
          }
        }

        this.addAllStops();
      });
    },
    markerUpdatePosition(stop_id, lat, lng) {
      var stop = { id: stop_id, lat: lat, lng: lng };
      this.$store.dispatch("editStop", stop);
    },
    addMarker(stop) {
      var loc = [stop.lat, stop.lng];

      var stopId =
        stop.position_on_the_route == 999999 ? "" : stop.position_on_the_route;
      var icon;
      if (stop.delivery_status == 0) {
        if (stop.priority == 1) {
          icon = "img/l2asap2.png";
        } else {
          icon = "img/l2.png";
        }
      } else {
        /* done */
        icon = "img/l2done.png";
      }

      var markerIcon = new L.DivIcon({
        className: "my-div-icon",
        html:
          `<div class='container'><img src='${icon}'><div class='top-left' style='font-size: 15px; ` +
          ` color: white; font-weight: bold; position: absolute; top: 13px; left: 130%; transform: translateX(-50%)'>${stopId}</div></div>`,
      });

      let isDraggagle = !this.isOptimized && !this.isDriverRoute;
      var marker = L.marker(loc, {
        icon: markerIcon,
        draggable: isDraggagle,
      });
      marker.addTo(this.map);
      marker.bindPopup(`<b>${stop.name}</b><br>` + stop.address);

      //
      var self = this;
      marker.on("dragend", function (e) {
        var marker = e.target;
        var location = marker.getLatLng();
        var lat = location.lat;
        var lon = location.lng;
        //
        self.markerUpdatePosition(stop.id, lat, lon);
      });
      this.markerArray.push(marker);
    },
    addAllStops: function () {
      this.clearStops();

      var lls = [];
      for (var i = 0; i < this.$store.getters.stops.length; i++) {
        var stop = this.$store.getters.stops[i];
        var loc = [stop.lat, stop.lng];
        lls.push(loc);
        this.addMarker(stop);
        //
        if (stop.geometry != null) {
          var latlngs = polyUtil.decode(stop.geometry);
          //
          var path = null;
          var route_color = this.$store.getters.getRouteColor(
            stop.vehicle_route_id
          );
          // var route_color = ROUTE_COLORS[stop.driver_id % ROUTE_COLORS.length];
          path = L.polyline(latlngs, {
            color: route_color,
          }).addTo(this.map);
          this.pathArray.push(path);
        }
      }
      ///this.loadDepartures();
      // if (this.startPointMarker) {
      //   var loc2 = [stop.lat, stop.lng];
      //   lls.push(loc2);
      // }
      // if (this.endPointMarker) {
      //   this.map.removeLayer(this.endPointMarker);
      // }

      var bounds = L.latLngBounds(lls);
      this.map.fitBounds(bounds);
    },
    clearStops() {
      //
      for (var i = 0; i < this.markerArray.length; i++) {
        this.map.removeLayer(this.markerArray[i]);
      }
      this.markerArray = [];
      for (var j = 0; j < this.pathArray.length; j++) {
        this.map.removeLayer(this.pathArray[j]);
      }
      this.pathArray = [];

      if (this.startPointMarker) {
        //this.map.removeLayer(this.startPointMarker);
      }
      if (this.endPointMarker) {
        this.map.removeLayer(this.endPointMarker);
      }

      for (var key in this.drivers) {
        var value = this.drivers[key];
        this.map.removeLayer(value);
      }
    },

    showStop(stopId) {
      for (var i = 0; i < this.$store.getters.stops.length; i++) {
        if (this.$store.getters.stops[i].id == stopId) {
          var stop = this.$store.getters.stops[i];
          var loc = [stop.lat, stop.lng];
          this.map.setView(loc, 16);
          break;
        }
      }
    },
    setValue: function (value) {
      this.value = value;
    },
    showDrivers(vehicleRouteIdx, lat, lng, driverName) {
      if (vehicleRouteIdx in this.drivers)
        this.map.removeLayer(this.drivers[vehicleRouteIdx]);

      var col = this.$store.getters.getRouteColor(vehicleRouteIdx);
      var style = `font-size: 34px; color:${col}; background: white; border-radius: 50%;`;
      let driverIcon = `<div class="block"><div class="icon"><p class="iconfix"><i class="material-icons" style='${style}'>account_circle</i></p></div></div>`;
      var markerIcon = new L.DivIcon({
        className: "my-div-icon",
        html: driverIcon,
      });

      var loc = [lat, lng]; //[50.270908, 19.039993];
      var marker = L.marker(loc, {
        icon: markerIcon,
        draggable: !this.isOptimized,
      });
      marker.addTo(this.map);
      marker.bindPopup(`<b>${driverName}</b>`);
      this.drivers[vehicleRouteIdx] = marker;
    },
    getDriversPostions() {
      this.$store.dispatch("getDriversPostions", stop).then((response) => {
        for (var i = 0; i < response.drivers.length; i++) {
          this.showDrivers(
            response.drivers[i].vehicle_route_id,
            response.drivers[i].lat,
            response.drivers[i].lng,
            response.drivers[i].driver_name
          );
        }
      });
    },
  },
  mounted() {
    this.setupLeafletMap();

    EventBus.$on(
      "ADD_MARKER_EVENT",
      function (stopId) {
        this.addMarker2(stopId);
      }.bind(this)
    );

    EventBus.$on(
      "SHOW_MARKER_EVENT",
      function (stopId) {
        this.showStop(stopId);
      }.bind(this)
    );

    EventBus.$on(
      "LOAD_ROUTE_EVENT",
      function () {
        this.clearStops();
        this.loadRoute();
        this.showDialog1 = true;
      }.bind(this)
    );

    EventBus.$on(
      "LOADING_ON",
      function () {
        this.isLoading = true;
      }.bind(this)
    );
    EventBus.$on(
      "LOADING_OFF",
      function () {
        this.isLoading = false;
      }.bind(this)
    );
    EventBus.$on(
      "DRIVER_POSITION_CHANGED_EVENT",
      function () {
        this.getDriversPostions();
      }.bind(this)
    );

    //this.showDrivers(1);
    this.loadRoute();
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },
  created() {
    this.clearStops();
  },
};
</script>

<style scoped>
#mapContainer {
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

/* Container holding the image and the text */
.container {
  position: relative;
  text-align: center;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.v-dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}

.block {
  width: 100px;
  height: 100px;
  background: grey;
}

.icon {
  background: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 1em;
  left: 1em;
}

.iconfix {
  position: absolute;
  top: -1.2em;
  left: -0.2em;
}
.material-icons {
  background: white;
}
</style>
