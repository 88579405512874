<template>
  <v-container
    fluid
    style="min-height: 0; padding-left: 40px; padding-right: 40px"
  >
    <article
      class="page text-left mobile-center padding-top-1x"
      style="width: 600px; margin-left: auto; margin-right: auto"
    >
      <div>
        <div class="row padding-bottom-3x">
          <h4>What's your team called?</h4>
        </div>

        <div class="row padding-bottom-4x">
          <label for="name">Full name:</label>
          <input
            id="name"
            name="name"
            v-model="name"
            type="text"
            value=""
            style="background-color: #ffffff; border-color: #c5c5c5"
            :placeholder="'enter display name in the app'"
          />
        </div>
      </div>
      <div class="row padding-bottom-2x">
        <div class="col-sm-10"></div>

        <div class="col-sm-2">
          <button
            class="btn btnl1 btn-block"
            style="width: 200px; position: absolute; right: 0px"
            @click="nextCall()"
            type="submit"
          >
            Next
          </button>
        </div>
      </div>
    </article>
    <dialogConfirm ref="confirm"></dialogConfirm>
  </v-container>
</template>

<script>
import countyUtils from "@/country-utils.js";
export default {
  components: {},
  data: () => ({
    email: "",
    name: "",
    latitude: null,
    longitude: null,
    countryCode: null,
    timezone: null,
  }),
  methods: {
    nextCall() {
      if (this.name != null && this.name.length > 0) {
        //save
        localStorage.teamName = this.name;

        localStorage.latitude = this.latitude;
        localStorage.longitude = this.longitude;
        localStorage.countryCode = this.countryCode;
        localStorage.timezone = this.timezone;
        localStorage.distanceUnit = 0;

        if (this.countryCode != null) {
          if (this.countryCode.localeCompare("US") == 0) {
            localStorage.distanceUnit = 1;
          }
        }

        this.$router.push({
          name: "OnboardingDepot",
        });
      } else {
        this.showInfo();
      }
    },
    async showInfo() {
      if (
        await this.$refs.confirm.openInfo(
          "Information",
          "The team name can not be empty",
          {
            color: "blue",
          }
        )
      ) {
        // yes
      }
    },
    saveInitialUserData(countryCode, latitude, longitude) {},
    getInitialUserData() {
      if (navigator.geolocation) {
        const success = (position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          // this.saveInitialUserData(countryCode, latitude, longitude);
          // Do something with the position
        };

        const error = (err) => {};

        // This will open permission popup
        navigator.geolocation.getCurrentPosition(success, error);
      }
    },
  },
  mounted() {
    // if (localStorage.name) {
    //   this.name = localStorage.name;
    // }
    if (localStorage.email) {
      this.email = localStorage.email;
    }
  },
  created() {
    this.getInitialUserData();
  },
  // watch: {
  //   name(newName) {
  //     localStorage.name = newName;
  //   },
  // },
};
</script>

<style scoped>
.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}
</style>