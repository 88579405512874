import axios from 'axios'

//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const instance = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_SERVER,
    headers: {
        'Authorization': {
            toString() {
                return `Bearer ${localStorage.getItem('token')}`
            }
        }
    }

})


export { instance };

