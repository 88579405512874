
<template>
  <v-container fluid style="width: 100%; padding: 0">
    <article class="text-center1 mobile-center1 v-full-height splash">
      <section class="fw-section">
        <div class="container fullwidthsec">
          <div class="page-title title-size-normal text-light"></div>

          <div class="row">
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
              <div class="page-title title-size-normal text-dark">
                <div class="container white1">
                  <div class="inner white1">
                    <div class="column white1">
                      <center>
                        <div class="titlelogin">Drop Us A Line</div>
                      </center>
                    </div>
                  </div>
                </div>
                <div class="white1">
                  <b
                    >Please tell us what you think. Comments, bug reports and
                    feature requests are welcome.</b
                  >
                </div>
              </div>

              <div class="form-group padding-bottom-2x">
                <div class="row padding-bottom-1x">
                  <div class="col-xs-0 text-left"></div>
                </div>
                <label for="email" class="control-label white1">Email</label>
                <input
                  type="text"
                  v-model="email"
                  name="email"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && !email }"
                  tabindex="110"
                />
                <div v-show="submitted && !email" class="subtitle error-msg">
                  Email is required
                </div>
              </div>
              <div class="form-group">
                <div class="row padding-bottom-1x">
                  <div class="col-xs-0 text-left"></div>
                </div>
                <div class="white1"><b>Message:</b></div>
                <textarea
                  cols="40"
                  id="message"
                  name="message"
                  rows="4"
                  v-model="message"
                  style="background-color: #ffffff; border-color: #c5c5c5"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && !message }"
                ></textarea>
                <div v-show="submitted && !message" class="subtitle error-msg">
                  Message is required
                </div>
              </div>

              <div class="form-group">
                <div class="row padding-bottom-2x"></div>
                <p class="subtitle error-msg">{{ errorMsg }}</p>
                <button
                  class="btn btn-ghost1 btn-rounded btn-default btn-block waves-effect1 waves-light1 btnl1"
                  type="submit"
                  @click="sendMessage()"
                >
                  Send message
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-3"></div>
          <div class="col-sm-3"></div>

          <div class="padding-bottom-2x"></div>
          <center>
            <router-link to="login"> Go back to Login</router-link>
          </center>
        </div>
      </section>
      <dialogConfirm ref="confirm"></dialogConfirm>
    </article>
    <vue-cookies-consent
      backgroundButton="#015a66"
      borderRadius="5"
      backgroundButtonHover="#3A4F52"
      offsetY="5"
    >
      <template #description>
        <p>
          We use cookies to ensure you get the best experience on our website
          <a href="https://maposcope.com/privacy-policy/"
            ><span style="color: #0b6f7a">Read more</span></a
          >
        </p>
      </template>
      <template #button>Accept cookies</template>
    </vue-cookies-consent>
  </v-container>
</template>

<script>
import VueCookiesConsent from "@norvikit/vue-cookies-consent";
export default {
  components: {
    VueCookiesConsent,
  },
  data() {
    return {
      email: "",
      message: "",
      errorMsg: "",
      //eslint-disable-next-line
      reg: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    };
  },
  computed: {},
  methods: {
    async showInfo() {
      if (
        await this.$refs.confirm.openInfo(
          "Message has been sent",
          "Thank you for your message. We'll get back to you shortly.'",
          {
            color: "blue",
          }
        )
      ) {
        // yes
        this.$router.push({
          name: "Login",
        });
      }
    },
    sendMessage() {
      if (this.email == null || this.email == "") {
        this.errorMsg = "Please enter email";
        return;
      } else if (!this.reg.test(this.email)) {
        this.errorMsg = "Please enter correct email";

        return;
      }
      if (
        this.email &&
        this.email.length > 4 &&
        this.message &&
        this.message.length > 3
      ) {
        this.errorMsg = "";
        this.showInfo();
        this.$store
          .dispatch("sendMessage", {
            email: this.email,
            message: this.message,
          })
          .then(() => {});
      } else {
        this.errorMsg = "Email and Message are required";
      }
    },
  },
};
</script>


<style scoped>
a {
  color: red;
}
.error-msg {
  color: red;
  font-weight: bold;
}

.wlink a,
a:active,
a:hover {
  color: #ffffff !important;
}
.v-application a {
  color: #ffffff !important;
}

.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}

.fullwidthsec {
  max-width: 900px;
}
</style>