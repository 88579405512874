<template>
  <v-container
    fluid
    style="min-height: 0; padding-left: 40px; padding-right: 40px"
  >
    <article
      class="page text-left mobile-center padding-top-1x"
      style="width: 600px; margin-left: auto; margin-right: auto"
    >
      <div>
        <div class="row padding-bottom-3x">
          <h4>Setup where drivers starts and ends</h4>
        </div>

        <div class="row padding-bottom-1x"></div>
        <label>Default Start location (where drivers begin their routes)</label>
        <strong>{{ startAddress }}</strong>
        <div class="padding-bottom-3x">
          <search
            hints="Find new start location"
            v-on:found-address="onSearchResultsStartLocation"
          ></search>
        </div>

        <div class="row padding-bottom-1x"></div>

        <label>Default End location: (where drivers end their routes)</label>
        <strong>{{ endAddress }}</strong>
        <div class="padding-bottom-3x">
          <search
            hints="Find new end location"
            v-on:found-address="onSearchResultsEndLocation"
          ></search>
        </div>

        <div class="row padding-bottom-2x">
          <div class="col-sm-10"></div>
          <div class="col-sm-2">
            <button
              class="btn btnl1 btn-block btn-ghost"
              style="width: 200px; position: absolute; right: 0px"
              @click="nextCall()"
              type="submit"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </article>
    <dialogConfirm ref="confirm"></dialogConfirm>
  </v-container>
</template>

<script>
//import Search from "./SearchMaposcope.vue";
import Search from "./SearchTomTom.vue";

export default {
  components: {
    Search,
  },
  data: () => ({
    startAddress: "",
    startAddressLat: "",
    startAddressLng: "",

    endAddress: "",
    endAddressLat: "",
    endAddressLng: "",
    distanceUnitVal: 0, //["KM", "Miles"],
    country: "",
  }),
  computed: {
    distanceUnit() {
      return localStorage.getItem("distanceUnit");
    },
  },
  methods: {
    async showInfo() {
      if (
        await this.$refs.confirm.openInfo(
          "Information",
          "The start and end location can not be empty",
          {
            color: "#015a66",
          }
        )
      ) {
        // yes
      }
    },
    onSearchResultsStartLocation: function (params) {
      this.startAddressLat = params.lat;
      this.startAddressLng = params.lng;
      this.startAddress = params.fullAddress;
      this.country = params.country;
      this.distanceUnitVal = this.getDistanceUnit(this.country);
      if (this.endAddressLat == null || this.endAddressLat.length == 0) {
        this.endAddressLat = this.startAddressLat;
        this.endAddressLng = this.startAddressLng;
        this.endAddress = this.startAddress;
      }
    },
    onSearchResultsEndLocation: function (params) {
      this.endAddressLat = params.lat;
      this.endAddressLng = params.lng;
      this.endAddress = params.fullAddress;
    },
    getDistanceUnit(countryCode) {
      if (countryCode != null && countryCode.length > 0) {
        if (countryCode.toUpperCase() === "US") {
          return 1;
        }
      }
      return 0;
    },
    nextCall() {
      if (
        this.startAddress != null &&
        this.startAddress.length > 0 &&
        this.endAddress != null &&
        this.endAddress.length > 0
      ) {
        //save
        localStorage.onb_startAddressLat = this.startAddressLat;
        localStorage.onb_startAddressLng = this.startAddressLng;
        localStorage.onb_startAddress = this.startAddress;
        localStorage.onb_endAddressLat = this.endAddressLat;
        localStorage.onb_endAddressLng = this.endAddressLng;
        localStorage.onb_endAddress = this.endAddress;

        if (localStorage.distanceUnit === null) {
          localStorage.distanceUnit = this.distanceUnitVal;
        }
        //localStorage.onb_countryCode = this.country;

        var pref = {
          name: localStorage.teamName,
          startAddress: localStorage.onb_startAddress,
          startLat: localStorage.onb_startAddressLat,
          startLng: localStorage.onb_startAddressLng,
          endAddress: localStorage.onb_endAddress,
          endLat: localStorage.onb_endAddressLat,
          endLng: localStorage.onb_endAddressLng,

          distanceUnit: localStorage.distanceUnit,
          countryCode: localStorage.countryCode,
          latitude: localStorage.latitude,
          longitude: localStorage.longitude,
          timezone: localStorage.timezone,
        };
        this.$store.dispatch("saveTeamPreferences", pref).then(() => {
          this.$router.push({ name: "OnboardingTeamMembers" });
        });
      } else {
        this.showInfo();
      }
    },
  },

  mounted() {
    // if (localStorage.startAddress) {
    //   this.startAddress = localStorage.startAddress;
    //   this.lat = localStorage.startLat;
    //   this.lng = localStorage.startLng;
    // }
  },
  // watch: {
  //   startAddress(newStartAddress) {
  //
  //     localStorage.startAddress = newStartAddress;
  //     localStorage.startLat = this.lat;
  //     localStorage.startLng = this.lng;
  //   },
  // },

  created() {},
};
</script>

<style scoped>
.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}
</style>