<template>
  <v-container
    fluid
    style="min-height: 0; padding-left: 40px; padding-right: 40px"
  >
    <article class="page text-left mobile-center padding-top-1x">
      <div>
        <center>
          <h4>
            <i
              class="material-icons check_circle"
              style="font-size: 28px; padding: 0 5px 10px; color: #46ab57"
            ></i
            >Admin teams
          </h4>
        </center>
        <div class="row padding-bottom-1x"></div>
      </div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        :headers="headers"
        :items="values"
        :items-per-page="20"
        class="elevation-3"
        style="font-size: 28px"
        :search="search"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editTeam(item)"> edit </v-icon>
        </template>
      </v-data-table>
    </article>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Open Dialog
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <center>
                plan_active_to_date_manual:{{ selectedDate }}
                <v-date-picker
                  v-model="selectedDate"
                  @dayclick="onDayClick"
                  v-on:change="onDate"
                  no-title
                  style="min-height: 460px"
                  width="350"
                />
              </center>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="clearManualDate()">
            Clear Manual Date
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
export default {
  components: {},
  data: () => ({
    currentPlan: "Pro", //Basic (Trial) / Pro
    name: "",
    search: "",
    loader: null,
    loading: false,
    dialog: false,
    selectedDate: null,
    values: [],
    headers: [
      { text: "id", value: "id" },
      {
        text: "name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Edit", value: "actions", sortable: false },
      { text: "plan_current_period_start", value: "plan_current_period_start" },
      { text: "plan_current_period_end", value: "plan_current_period_end" },
      {
        text: "plan_active_to_date_manual",
        value: "plan_active_to_date_manual",
      },

      { text: "created_at", value: "created_at" },
      { text: "plan_customer_id", value: "plan_customer_id" },
      { text: "plan_subscription_id", value: "plan_subscription_id" },
      { text: "plan_quantity", value: "plan_quantity" },
      { text: "plan_product", value: "plan_product" },

      { text: "users", value: "users" },
    ],
  }),
  methods: {
    clearManualDate() {
      this.dialog = false;
      var payload = {
        team_id: this.editedItemTeamId,
        to_date: null,
      };
      this.$store.dispatch("saveTeamData", payload).then(() => {
        this.loadTeams();
      });
      this.editedItemTeamId = -1;
    },
    save() {
      this.dialog = false;

      var payload = {
        team_id: this.editedItemTeamId,
        to_date: this.selectedDate,
      };
      this.$store.dispatch("saveTeamData", payload).then(() => {
        //this.processResponse(response.values);
        this.loadTeams();
      });
      this.editedItemTeamId = -1;
    },
    editTeam(item) {
      this.editedItemTeamId = item.id;
      this.dialog = true;
    },
    processResponse(values) {
      this.values = values;
    },
    dateStr(date0) {
      var utcSeconds = date0;
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);
      var options = {
        year: "numeric",
        weekday: "short",
        month: "short",
        day: "numeric",
      };
      var d2 = d.toLocaleDateString("default", options);
      return d2;
    },

    loadTeams() {
      this.$store.dispatch("getAllTeams").then((response) => {
        this.processResponse(response.values);
      });
    },
  },
  mounted() {
    this.$store.dispatch("getAllTeams").then((response) => {
      this.processResponse(response.values);
    });
  },
};
</script>
<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
  color: #ffffff;
  background-color: red;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

table.v-table thead th {
  font-size: 14px !important;
}
table.v-table tbody td {
  font-size: 14px !important;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
}
.v-data-table::v-deep td {
  font-size: 12px !important;
}
</style>

