<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 0px;
      padding-right: 0px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <div></div>

          <div class="rowm">
            <center>
              <button
                class="btn btnl1 btn-block"
                @click="newRoute()"
                type="submit"
              >
                New Route
              </button>
            </center>
          </div>

          <center>
            <v-date-picker
              v-model="selectedDate"
              @dayclick="onDayClick"
              v-on:change="onDate"
              no-title
              width="350"
              :events="routeDates"
            />
          </center>

          <section class="widget widget_recent_entries padding-bottom-1x">
            <h3 class="widget-title">
              <div class="rowm">
                Routes ({{ routesCount }}):
                <v-btn
                  text
                  color="#015a66"
                  @click="getAllRoutes()"
                  style="float: right"
                >
                  Get all routes
                </v-btn>
              </div>
            </h3>

            <v-expansion-panels accordion focusable>
              <v-expansion-panel v-for="(itemDate, i) in routeDates" :key="i">
                <v-expansion-panel-header>
                  <div class="lititle1">
                    <i
                      class="material-icons room"
                      style="
                        font-size: 14px;
                        padding-bottom: 5px;
                        padding-right: 5px;
                        color: #002e36;
                      "
                    ></i
                    >{{ itemDate }}
                  </div></v-expansion-panel-header
                >
                <v-expansion-panel-content id="innerExPan">
                  <ul>
                    <li
                      style="padding: 14px 0px 0px 15px"
                      v-for="item in routePerDate[i]"
                      :key="item.id"
                      @click="openRoute(item.id)"
                    >
                      <div class="lititle1">
                        <i
                          class="material-icons room"
                          style="
                            font-size: 18px;
                            padding-bottom: 5px;
                            padding-right: 5px;
                            color: #002e36;
                          "
                        ></i
                        >{{ item.name }}
                      </div>
                      <i
                        class="material-icons keyboard_arrow_right text-default"
                        style="
                          font-size: 24px;
                          padding-bottom: 5px;
                          color: rgba(0, 0, 0, 0.54);
                          float: right;
                        "
                      ></i>

                      <span
                        v-if="item.desc"
                        style="
                          margin-left: 25px;
                          color: #181818;
                          font-style: italic;
                        "
                        class="post-date"
                        >{{ item.desc }}</span
                      >

                      <span
                        style="
                          margin-left: 25px;
                          font-size: 10px;
                          display: inline-block;
                        "
                        class="post-date"
                      >
                        created: {{ getDateTime(item.created_at) }}
                        <br />updated: {{ getDateTime(item.updated_at) }}</span
                      >
                      <br />
                      <button
                        class="btn btnl1 btn-sm btn-ghost"
                        @click="generateRouteRaport(item.id, item.name)"
                        v-on:click.stop
                        type="submit"
                        style="margin-left: 25px; font-size: 14px"
                      >
                        <i
                          class="material-icons picture_as_pdf text-default"
                          style="
                            font-size: 14px;
                            padding-bottom: 5px;
                            color: #ffffff;
                          "
                        ></i>
                        Generate report
                      </button>
                      <button
                        class="btn btnl1 btn-sm btn-ghost"
                        @click="copyRoute(item.id)"
                        v-on:click.stop
                        type="submit"
                        style="margin-left: 25px; font-size: 14px"
                      >
                        <i
                          class="
                            material-icons
                            content_copy
                            text-default
                            white1
                          "
                          style="
                            font-size: 14px;
                            padding-bottom: 5px;
                            color: #ffffff;
                          "
                        ></i>
                        Copy route
                      </button>

                      <button
                        class="btn btn-danger btn-sm btn-ghost"
                        @click="deleteRoute(item.id, item.name)"
                        v-on:click.stop
                        type="submit"
                        style="font-size: 14px"
                      >
                        <i
                          class="material-icons delete text-default"
                          style="font-size: 14px; padding-bottom: 5px"
                        ></i>
                        Delete route
                      </button>
                      <small style="margin-left: 25px; font-style: italic">
                        <div v-if="item.is_optimized">
                          This route has been optimized.
                        </div>
                        <div v-else>This route hasn't been optimized yet.</div>
                      </small>
                    </li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </section>
        </div>
        <dialogConfirm ref="confirm"></dialogConfirm>
      </section>
    </article>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
//import Calendar from "v-calendar/lib/components/calendar.umd";
//import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  components: {
    // Calendar,
    // DatePicker,
  },
  data() {
    return {
      welcome: "This is your profile2",
      id: 0,
      message: "23423444",
      showDeleteMessage: false,

      nameModel: "not set",
      addressModel: "not set",
      noteModel: "",
      isLogged: false,
      routeDates: [],
      routePerDate: [],
      selectedDate: null,
      arrayEvents: null,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],

      isConnected: false,
      socketMessage: "",
    };
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
      this.sendUserToken();
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data;
    },
    messageChannelStopChanged(data) {
      this.socketMessage = data;
    },
    messageChannelRouteChanged(data) {
      this.socketMessage = data;
      this.getAllRoutes();
    },
  },
  methods: {
    pingServer() {
      // Send the "pingServer" event to the server.
      this.$socket.emit("message", "PING from Routes!");
    },
    getRouteTitle(newDate) {
      var options = {
        weekday: "long",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      var d;
      if (newDate != null) {
        d = new Date(newDate).toLocaleDateString("default", options);
      } else {
        d = new Date().toLocaleDateString("default", options);
      }
      return d;
    },
    newRoute() {
      this.$router.push({
        name: "ManageRoutePlannedDate",
      });
    },
    openRoute(route_id) {
      this.$store.commit("setCurrentRoute", route_id);
      for (var i = 0; i < this.$store.getters.routes.length; i++) {
        if (this.$store.getters.routes[i].id == route_id) {
          this.$store.commit(
            "setCurrentRouteName",
            this.$store.getters.routes[i].name
          );
          break;
        }
      }
      this.$router.push({ name: "Stops" });
    },
    openPreferences() {
      this.$router.push({ name: "Preferences" });
    },

    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
    copyRoute(route_id) {
      this.$store.dispatch("copyRoute", route_id).then(() => {
        this.$store.dispatch("getRoutes").then((response) => {
          this.processRoutes(response.routes);
        });
        this.showInfo();
      });
    },
    generateRouteRaport(route_id, route_name) {
      var pdf_name = route_name + ".pdf";
      this.$store.dispatch("generateRouteReport", route_id).then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", pdf_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    async showInfo() {
      if (
        await this.$refs.confirm.openInfo(
          "Information",
          "The route has been copied",
          {
            color: "blue",
          }
        )
      ) {
        // yes
      }
    },
    async deleteRoute(route_id, route_name) {
      if (
        await this.$refs.confirm.openConfirm(
          "Delete",
          `Do you really want to delete route - "${route_name}"?`,
          {
            color: "blue",
          }
        )
      ) {
        // yes

        this.$store.dispatch("deleteRoute", route_id).then(() => {
          this.$store.dispatch("getRoutes").then((response) => {
            this.processRoutes(response.routes);
          });
        });
      } else {
        // cancel
      }
    },
    getDateTime(dateTimeIso) {
      //
      var a = new Date(dateTimeIso);
      return a.toLocaleString();
    },
    processRoutes(responseRoutes) {
      var rs = responseRoutes;
      this.routeDates = [];
      this.routePerDate = [];

      var lastDate = "";
      var arr = Array();
      for (var i = 0; i < rs.length; i++) {
        var d = rs[i]["planned_on"];
        if (lastDate !== d) {
          if (arr.length > 0) {
            this.routePerDate.push(arr);
          }
          this.routeDates.push(d);
          arr = Array();
          lastDate = d;
        }
        arr.push(rs[i]);
      }

      if (arr.length > 0) {
        this.routePerDate.push(arr);
      }

      this.arrayEvents = [...Array(6)].map(() => {
        const day = Math.floor(Math.random() * 30);
        const d = new Date();
        d.setDate(day);
        return d.toISOString().substr(0, 10);
      });
    },
    onDate() {
      if (this.selectedDate != null) {
        this.$store
          .dispatch("getRoutes", this.selectedDate)
          .then((response) => {
            this.processRoutes(response.routes);
          });
      }
    },
    getAllRoutes() {
      this.selectedDate = null;

      this.$store.dispatch("getRoutes").then((response) => {
        this.processRoutes(response.routes);
      });
    },

    pingServerUserLoggedIn() {
      // Send the "pingServer" event to the server.
      this.sendUserToken();
    },
    sendUserToken() {
      let token = localStorage.getItem("token");
      this.$socket.emit("userLoggedIn", token);
    },
    clearData() {
      this.$store.commit("clear");
    },
  },
  computed: {
    ...mapGetters("auth", {
      isAuth: "isAuthenticated",
    }),
    routes() {
      return this.$store.getters.routes;
    },
    routesCount() {
      return this.$store.getters.routes.length;
    },
    currentEmail() {
      return this.$store.state.auth.username;
    },
    currentTeam() {
      return this.$store.state.auth.teamname;
    },
  },
  mounted() {
    this.$store.dispatch("getRoutes").then((response) => {
      this.processRoutes(response.routes);
    });
    this.pingServerUserLoggedIn();
  },
  created() {
    this.clearData();
  },
};
</script>

<style scoped>
ul li:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.lititle1 {
  color: #002e36;
  font-weight: bold;
  font-size: 14px;
}

#innerExPan {
  padding-top: 0px;
  padding-right: 00px;
  padding-bottom: 0px;
  padding-left: 00px;
}

ul li::before {
  content: "";
}

ul li {
  content: "";
}
.v-application ul,
.v-application ol {
  padding: 0px;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.email {
  color: #969696;
  font-size: 14px;
  margin-left: 10px;
}

.team {
  color: #002e36;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

v-list v-list-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

#menu-item {
  padding: 10px 20px 0px 20px;
}

.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}
</style>