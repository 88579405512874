<template>
  <v-container
    fluid
    style="min-height: 0; padding-left: 40px; padding-right: 40px"
  >
    <article class="page text-left mobile-center padding-top-1x">
      <div>
        <div class="row padding-bottom-6x"></div>

        <center>
          <h4>
            <i
              class="material-icons check_circle"
              style="font-size: 28px; padding: 0 5px 10px; color: #46ab57"
            ></i>
            Subscription to Maposcope Driver plan successful!
          </h4>
        </center>

        <div class="row padding-bottom-1x"></div>
      </div>
      <dialogConfirm ref="confirm"></dialogConfirm>
    </article>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    currentPlan: "Pro", //Basic (Trial) / Pro
    name: "",
    loader: null,
    loading: false,
  }),
  methods: {
    upgradePlan() {
      this.loader = "loading";
      this.$store.dispatch("getCheckout").then((response) => {
        this.loader = null;
        this.loading = false;
        if (response.data.err == 0) {
          window.location.href = response.data.url;
        }
      });
    },
    async cancelSubscriptionNow() {
      if (
        await this.$refs.confirm.openConfirm(
          "How can we help?",
          "Don’t leave us just yet! We’d love to get another chance. Please get in touch so we can chat. Otherwise, click on “OK” to continue cancellation process.",
          {
            color: "blue",
            width: 600,
          }
        )
      ) {
        // yes
      } else {
        // cancel
      }
    },
  },
  watch: {
    name(newName) {
      localStorage.name = newName;
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
  color: #ffffff;
  background-color: red;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

