<template>
  <v-container
    fluid
    style="min-height: 0; padding-left: 40px; padding-right: 40px"
  >
    <article class="page text-left mobile-center padding-top-1x">
      <div>
        <div class="row padding-bottom-3x">
          <h4>Manage My Subscriptions</h4>
        </div>

        <div class="row padding-bottom-1x">
          <div class="text6">Current plan:</div>
          <div class="text7">{{ currentPlan }}</div>
        </div>

        <div class="row">
          <h5>Plan Usage</h5>
        </div>

        <div class="row">
          <div class="text4">Active to: {{ validToDateStr }}</div>
        </div>
        <div class="row">
          <div class="text4">Drivers: {{ driversCount }}</div>
        </div>
        <div class="row" v-if="!isSubscriptionActive">
          <div class="text4" style="color: red">
            Your subscription has expired
          </div>
        </div>

        <div class="row" v-if="has_customer">
          <div class="col-sm-6"></div>

          <div class="col-sm-6">
            <v-btn
              elevation="5"
              x-large
              :loading="loading2"
              color="#015a66"
              @click="managePayments()"
              style="float: right"
              class="white--text"
            >
              <div class="btn">Manage payments</div>
            </v-btn>
          </div>
        </div>

        <div class="row padding-bottom-2x"></div>
        <div class="row" v-if="!isActive">
          <div class="border1">
            <div class="row">
              <div class="col-sm-6">
                <div class="text6">
                  Unlock subscription plan to grow your business
                </div>
              </div>

              <div class="col-sm-6">
                <v-btn
                  elevation="5"
                  x-large
                  :loading="loading"
                  color="#015a66"
                  @click="upgradePlan()"
                  style="float: right"
                  class="white--text"
                >
                  <div class="btn">Subscribe</div>
                </v-btn>
              </div>
            </div>
            <hr
              class="hr-solid hr-primary"
              style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
            />
            <table>
              <tr>
                <td>
                  <i class="material-icons done check1"></i>Route optimization
                  up to 200 stops
                </td>
                <td>
                  <i class="material-icons done check1"></i>Live route progress
                </td>
              </tr>
              <tr>
                <td>
                  <i class="material-icons done check1"></i>Spreadsheet import
                </td>
                <td>
                  <i class="material-icons done check1"></i>
                  Support for time window, priority and visit time
                </td>
              </tr>
              <tr>
                <td>
                  <i class="material-icons done check1"></i>Proof of delivery
                </td>
                <td>
                  <i class="material-icons done check1"></i>
                  Android App
                </td>
              </tr>
              <tr>
                <td>
                  <i class="material-icons done check1"></i>Spreadsheet import
                </td>
                <td>
                  <i class="material-icons done check1"></i>
                  Data synced and backed up to cloud
                </td>
              </tr>
              <tr>
                <td>
                  <i class="material-icons done check1"></i>Dispatcher web app
                </td>
                <td>
                  <i class="material-icons done check1"></i>
                  Assign routes to drivers
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="row padding-bottom-2x"></div>
      </div>
      <dialogConfirm ref="confirm"></dialogConfirm>
    </article>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    has_customer: false, //==true has cus_id and can check stripe portal
    isActive: false,
    validToDate: null,
    driversCount: 1,
    driversCurrentCount: 0,
    currentPlan: "Basic (Trial)", //Basic (Trial) / Pro
    isSubscriptionActive: false,
    name: "",
    loader: null,
    loading: false,
    loading2: false,
  }),
  methods: {
    managePayments() {
      this.loader = "loading2";
      this.$store.dispatch("getPaymentAccount").then((response) => {
        this.loader = null;
        this.loading2 = false;
        if (response.data.err == 0) {
          window.location.href = response.data.url;
        }
      });
    },
    upgradePlan() {
      this.loader = "loading";
      this.$store.dispatch("getCheckout").then((response) => {
        this.loader = null;
        this.loading = false;
        if (response.data.err == 0) {
          window.location.href = response.data.url;
        }
      });
    },
  },
  computed: {
    validToDateStr() {
      var utcSeconds = this.validToDate;
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);
      var options = {
        year: "numeric",
        weekday: "short",
        month: "short",
        day: "numeric",
      };
      var d2 = d.toLocaleDateString("default", options);
      return d2;
    },
  },
  watch: {
    name(newName) {
      localStorage.name = newName;
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },
  created() {
    this.$store.dispatch("getSubscription").then((response) => {
      if (response.data.subscription.valid_to_date)
        this.validToDate = response.data.subscription.valid_to_date;
      this.has_customer = response.data.subscription.has_customer;
      this.isActive = response.data.subscription.is_active;
      if (response.data.subscription.driver_count)
        this.driversCount = response.data.subscription.driver_count;
      if (response.data.subscription.driver_count_current)
        this.driversCurrentCount =
          response.data.subscription.driver_count_current; //nowa liczba do zchargowania

      if (response.data.subscription.is_subscription_active)
        this.isSubscriptionActive =
          response.data.subscription.is_subscription_active;

      if (this.isActive) {
        this.currentPlan = "Professional - Driver";
      } else {
        this.currentPlan = "Basic (trial)";
      }
    });
  },
};
</script>
<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
  color: #ffffff;
  background-color: red;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

ul li::before {
  content: "";
}

ul li::before {
  content: "";
  font-size: 23px;
  font-weight: bold;
  padding: 0 5px 5px 0;
  color: #4b5af3;
  vertical-align: middle;
  line-height: normal;
}

ul li {
  content: "";
}

.check1 {
  font-size: 23px;
  font-weight: bold;
  padding: 0 5px 5px 0;
  color: #4b5af3;
  line-height: normal;
}
table {
  border: none;
}
</style>

