<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 20px;
      padding-right: 20px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <!-- header -->
          <table class="table table-borderless" style="border: none !important">
            <tr>
              <td>
                <router-link to="" @click.native="back()"
                  ><span class="material-icons" style="font-size: 36px">
                    chevron_left
                  </span></router-link
                >
              </td>
              <td style="width: 80%; text-align: left">
                <div class="headerLine">Routes</div>
              </td>
              <td style="width: 10%; text-align: right; font-size: 24px"></td>
            </tr>
          </table>
          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
          <div class="row padding-bottom-1x"></div>

          <center>
            <div class="headerLine">When will you drive this route?</div>

            <v-radio-group v-model="radios" v-on:change="onDateRadioChange">
              <v-radio :label="today" value="1"></v-radio>
              <v-radio :label="tommorow" value="2"></v-radio>
            </v-radio-group>

            <div style="text-align: left; padding-left: 20px">
              {{ selectedDateString }}
            </div>

            <v-menu
              ref="menuFrom"
              v-model="menuFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  label="Pick a date"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="selectedDateByPicker"
                v-on:change="onDate"
                no-title
                @input="menuFrom = false"
              />
            </v-menu>
          </center>
          <div class="row padding-bottom-3x"></div>

          <div class="rowm">
            <div class="row" style="display: block; float: right">
              <button
                class="btn btnl1 btn-block"
                @click="newRoute()"
                type="submit"
              >
                Create Route
              </button>

              <div class="col-sm-3"></div>
              <div class="col-sm-3"></div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </v-container>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      radioGroup: 1,
      radios: null,
      selectedDate: null,
      selectedDateString: null,
      selectedDateByPicker: null,
      selecteToday: null,
      selecteTommorow: null,
      dateFrom: "",
    };
  },
  computed: {
    today() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let label = "Today, " + this.getRouteTitle(today);
      return label;
    },
    tommorow() {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      let label = "Tomorrow, " + this.getRouteTitle(tomorrow);
      return label;
    },
  },
  methods: {
    back() {
      //this.$store.dispatch("saveDrivers", this.selected).then(() => {
      this.$router.go(-1);
      //});
    },
    getDateString(yourDate) {
      const offset = yourDate.getTimezoneOffset();
      yourDate = new Date(yourDate.getTime() - offset * 60 * 1000);
      let d = yourDate.toISOString().split("T")[0];
      return d;
    },
    onDateRadioChange() {
      if (this.radios == "1") {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        this.selectedDate = this.getDateString(today);
      } else if (this.radios == "2") {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        this.selectedDate = this.getDateString(tomorrow);
      }
      this.selectedDateString = null;
    },
    onDate() {
      this.radios = null;
      this.selectedDate = this.selectedDateByPicker;
      this.selectedDateString = this.getRouteTitle(this.selectedDateByPicker);
    },
    getRouteTitle(newDate) {
      var options = {
        weekday: "long",
        month: "short",
        day: "numeric",
      };
      var d;
      if (newDate != null) {
        d = new Date(newDate).toLocaleDateString("default", options);
      } else {
        d = new Date().toLocaleDateString("default", options);
      }
      return d;
    },
    save() {
      this.$store.dispatch("saveDrivers", this.selected).then(() => {
        this.$router.go(-1);
      });
    },
    newRoute() {
      var planned_on;
      if (this.selectedDate != null) {
        planned_on = new Date(this.selectedDate);
      } else {
        planned_on = new Date();
      }

      var date = this.getRouteTitle(this.selectedDate);
      var title = date;
      var desc = "";
      var payload = { name: title, desc: desc, planned_on: planned_on };
      this.$store.dispatch("createNewRoute", payload).then(() => {
        this.$router.push({
          name: "ManageDriversNewRoute",
        });
      });
    },
  },

  created() {
    this.radios = "1";
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.selectedDate = this.getDateString(today);
  },
};
</script>

<style scoped>
table tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
  max-height: 500px;
}
.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}
</style>