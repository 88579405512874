<template>
  <v-dialog
    scrollable
    v-model="show"
    :max-width="options.width"
    style="z-index: 1; background-color: #f3f3f3"
  >
    <v-card style="background-color: #f3f3f3">
      <v-toolbar color="primary" dark v-if="title">
        <div class="title2">{{ title }}</div></v-toolbar
      >
      <div class="message2">
        {{ message }}
      </div>
      <v-card-text></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="show = false"
          ><div class="button2">OK</div></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  props: {
    value: Boolean,
    message: String,
    title: String,
  },
  components: {},
  data() {
    return {
      options: {
        color: "#234233",
        width: 420,
        zIndex: 200,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
  },
  created() {},
};
</script>


<style scoped>
/*.message {
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
  padding-bottom: 10px;
  font-size: 14px;
  padding: 20px;
}*/
</style>
