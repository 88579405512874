<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 0px;
      padding-right: 0px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <!-- header -->
          <div class="rowmh">
            <table
              class="table table-borderless"
              style="border: none !important"
            >
              <tr>
                <td>
                  <router-link to="" @click.native="$router.go(-1)"
                    ><span class="material-icons" style="font-size: 36px">
                      chevron_left
                    </span></router-link
                  >
                </td>
                <td style="width: 80%; text-align: left">
                  <div class="headerLine">
                    {{ routeName }}
                  </div>
                </td>
                <td style="width: 10%; text-align: right; font-size: 24px">
                  <router-link :to="editRoute" @click.native="editRoute()">
                    <span class="material-icons"> edit </span></router-link
                  >
                </td>
              </tr>
            </table>
          </div>
          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
          <div class="row padding-bottom-1x"></div>
          <!-- header -->

          <label class="file-select white1">
            <div class="select-button">
              <span class="material-icons white1" style="font-size: 40px">
                file_upload
              </span>
              <span v-if="value"
                ><div class="white1">
                  Selected File: {{ this.file.name }}
                </div></span
              >
              <span v-else
                ><div class="white1">
                  Select File (press here) (.xlsx, .xls, .csv)
                </div></span
              >
            </div>
            <input type="file" @change="onChange" />
          </label>

          <table class="table table-borderless" style="border: none !important">
            <tr>
              <td
                style="
                  width: 40%;
                  text-align: right;
                  font-weight: bold;
                  padding-right: 30px;
                "
              >
                City
              </td>
              <td>
                <v-combobox
                  v-model="cityItems"
                  :items="items"
                  style="max-width: 250px"
                >
                </v-combobox>
              </td>
            </tr>
            <tr>
              <td
                style="
                  width: 20%;
                  text-align: right;
                  font-weight: bold;
                  padding-right: 30px;
                "
              >
                Address/Location/Street
              </td>
              <td>
                <v-combobox
                  v-model="addressItems"
                  :items="items"
                  style="max-width: 250px"
                >
                </v-combobox>
              </td>
            </tr>
            <tr>
              <td
                style="
                  width: 20%;
                  text-align: right;
                  font-weight: bold;
                  padding-right: 30px;
                "
              >
                Zip/Postcode/ Country
              </td>
              <td>
                <v-combobox
                  v-model="zipItems"
                  :items="items"
                  style="max-width: 250px"
                >
                </v-combobox>
              </td>
            </tr>

            <tr>
              <td
                style="
                  width: 20%;
                  text-align: right;
                  font-weight: bold;
                  padding-right: 30px;
                "
              >
                Latitude
              </td>
              <td>
                <v-combobox
                  v-model="latItems"
                  :items="items"
                  style="max-width: 250px"
                >
                </v-combobox>
              </td>
            </tr>

            <tr>
              <td
                style="
                  width: 20%;
                  text-align: right;
                  font-weight: bold;
                  padding-right: 30px;
                "
              >
                Longitude
              </td>
              <td>
                <v-combobox
                  v-model="lonItems"
                  :items="items"
                  style="max-width: 250px"
                >
                </v-combobox>
              </td>
            </tr>

            <tr>
              <td
                style="
                  width: 20%;
                  text-align: right;
                  font-weight: bold;
                  padding-right: 30px;
                "
              >
                Country
              </td>
              <td>
                <v-combobox
                  v-model="countryItems"
                  :items="countryItemsList"
                  item-text="cname"
                  style="max-width: 250px"
                >
                </v-combobox>
              </td>
            </tr>
          </table>

          <button
            class="btn btnl1 btn-block btn-sm"
            @click="mapData()"
            type="submit"
          >
            Map your data
          </button>
        </div>
      </section>
    </article>
    <dialogConfirm ref="confirm"></dialogConfirm>
  </v-container>
</template>
<script>
//import XlsxRead from "./XlsxRead";
//import XlsxJson from "./XlsxJson";
import { EventBus } from "./event-bus";
import WorkbookHandler from "../mixins/WorkbookHandler";
import SheetTo from "../mixins/SheetTo";
import {
  column_names_city,
  column_names_address,
  column_names_zip,
  column_names_lat,
  column_names_lon,
  isoCountries,
  max_file_size,
} from "../consts";

const COLUMN_INDEX_BG = { CITY: 0, ADDRESS: 1, POSTCODE: 2, LAT: 3, LON: 4 };
Object.freeze(COLUMN_INDEX_BG);

export default {
  mixins: [WorkbookHandler, SheetTo],

  components: {
    //XlsxRead,
    //XlsxJson,
  },
  data() {
    return {
      items: [],
      cityItems: [],
      addressItems: [],
      zipItems: [],
      latItems: [],
      lonItems: [],
      countryItemsList: [],
      countryItems: [],
      columnConfig: [],
      headerNames: [],
      file: null,
      value: 0,
      country: "en", //fixme
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    onChange(event) {
      var file = event.target.files ? event.target.files[0] : null;

      if (
        (file.name.endsWith(".xlsx") ||
          file.name.endsWith(".xls") ||
          file.name.endsWith(".csv")) &&
        file.size < max_file_size
      ) {
        this.file = file;
        this.value = 1;
        if (this.file) {
          this.parseFile(this.file);
        }
      } else {
        this.showInfo("Error", "Cant't parse this file!");
      }
    },
    async showInfo(title, msg) {
      if (
        await this.$refs.confirm.openInfo(title, msg, {
          color: "blue",
        })
      ) {
        // yes
      }
    },
    async load() {
      const { globalPolyfill } = require("../polyfills");
      globalPolyfill();
      const { read } = await import("xlsx");
      this._read = read;
      this.libLoaded = true;
      this._callbackQueue = [];

      const {
        utils: { sheet_to_json, decode_range, encode_col },
      } = await import("xlsx");
      this._sheet_to_json = sheet_to_json;
      this._decode_range = decode_range;
      this._encode_col = encode_col;

      this.loaded = true;
    },
    parseFile(file) {
      this.startLoading();
      const reader = new FileReader();
      reader.onload = (e) => {
        let binary = "";
        const bytes = new Uint8Array(e.target.result);
        const length = bytes.byteLength;
        for (var i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        this._workbook = this._read(binary, {
          type: "binary",
          ...this.options,
        });
        //this.fireCallBacks();
        //this.$emit("parsed", this._workbook);
        this.endLoading();

        this.updateJson(this._workbook);
      };
      reader.onerror = (e) => {};
      reader.readAsArrayBuffer(file);
    },
    updateJson(workbook) {
      const ws = workbook.Sheets[this.sheetNameFinder(workbook)];
      this.collection = this._sheet_to_json(ws, { defval: "" }, this.options);

      //this.$emit("parsed", this.collection);
      //get titles
      if (this.collection.length > 0) {
        const header = [];
        const columnCount = this._decode_range(ws["!ref"]).e.c + 1;
        for (let i = 0; i < columnCount; ++i) {
          header[i] = ws[`${this._encode_col(i)}1`].v;
        }
        if (header.length > 0) {
          this.setupColumns(header);
        }
      }
    },
    setupColumns(headerNames) {
      this.headerNames = headerNames;

      var arr = [""];
      this.items = arr.concat(headerNames);
      var idx;
      //city
      idx = this.getColumnNameIdx2(headerNames, column_names_city);
      if (idx != -1) {
        this.cityItems = headerNames[idx];
      }
      idx = this.getColumnNameIdx2(headerNames, column_names_address);
      if (idx != -1) {
        this.addressItems = headerNames[idx];
      }
      idx = this.getColumnNameIdx2(headerNames, column_names_zip);
      if (idx != -1) {
        this.zipItems = headerNames[idx];
      }
      idx = this.getColumnNameIdx2(headerNames, column_names_lat);
      if (idx != -1) {
        this.latItems = headerNames[idx];
      }
      idx = this.getColumnNameIdx2(headerNames, column_names_lon);
      if (idx != -1) {
        this.lonItems = headerNames[idx];
      }
    },

    getColumnNameIdx2(columns, column_names) {
      for (var i = 0; i < columns.length; i++) {
        var name = columns[i].toLowerCase();
        for (var j = 0; j < column_names.length; j++) {
          if (name.indexOf(column_names[j]) != -1) {
            return i; // matched
          }
        }
      }
      return -1;
    },
    getColumnNameIdxForName(name, column_names) {
      for (var i = 0; i < column_names.length; i++) {
        if (name.indexOf(column_names[i]) != -1) {
          return i; // matched
        }
      }
      return -1;
    },
    mapData() {
      this.readConfiguredColumns();
      var countryCode = this.readCountry();
      var values = this.prepareValues();

      var payload = {
        values: values,
        columnConfig: this.columnConfig,
        countryCode: countryCode,
      };
      //todo country
      EventBus.$emit("LOADING_ON");
      this.$store.dispatch("batchGeocode", payload).then(() => {
        EventBus.$emit("LOADING_OFF");
        this.$router.go(-1);
      });
    },
    readCountry() {
      if (this.countryItems.length == 0) {
        return "";
      } else {
        return this.countryItems["ccode"];
      }
    },
    readConfiguredColumns() {
      this.columnConfig = Array(5);
      for (var i = 0; i < this.columnConfig.length; i++) {
        this.columnConfig[i] = -1;
      }

      var idx;
      idx = this.getColumnNameIdxForName(this.cityItems, this.headerNames);
      if (idx != -1) this.columnConfig[COLUMN_INDEX_BG.CITY] = idx;

      idx = this.getColumnNameIdxForName(this.addressItems, this.headerNames);
      if (idx != -1) this.columnConfig[COLUMN_INDEX_BG.ADDRESS] = idx;

      idx = this.getColumnNameIdxForName(this.zipItems, this.headerNames);
      if (idx != -1) this.columnConfig[COLUMN_INDEX_BG.zipItems] = idx;

      idx = this.getColumnNameIdxForName(this.latItems, this.headerNames);
      if (idx != -1) this.columnConfig[COLUMN_INDEX_BG.LAT] = idx;

      idx = this.getColumnNameIdxForName(this.lonItems, this.headerNames);
      if (idx != -1) this.columnConfig[COLUMN_INDEX_BG.LON] = idx;
    },
    prepareValues() {
      var rowCount = this.collection.length;
      var values = new Array(rowCount);

      for (var i = 0; i < rowCount; i++) {
        values[i] = [];
        var row = this.collection[i];
        //
        var coumns = Object.values(row);
        //
        for (var j = 0; j < coumns.length; j++) {
          values[i][j] = coumns[j];
        }
      }

      return values;
    },
  },
  created() {
    this.countryItemsList = isoCountries;
  },
};
</script>

<style scoped>
.file-select > .select-button {
  padding: 3rem;

  color: white;
  background-color: #015a66;

  border-radius: 0.3rem;

  text-align: center;
  font-weight: bold;
}

.file-select > input[type="file"] {
  display: none;
}
.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}

input {
  background-color: #ffffff;
  border-color: #c5c5c5;
}
</style>