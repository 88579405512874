import router from '@/router'

import { instance } from '@/api'

const state = {
    username: '',
    token: null,
    teamname: '',
    email: '',
};

const mutations = {
    authUser(state, userData) {
        state.username = userData.username;
        state.token = userData.token;
        state.teamname = userData.team;
    },
    updateTeam(state, team) {
        state.teamname = team;
    },
    clearAuthData(state) {
        state.username = null;
        state.token = null;
    },
};

const getters = {
    isAuthenticated(state) {
        return state.token !== null;
    },
};

const actions = {
    register: ({ commit }, authData) => {

        return new Promise((resolve, reject) => {
            instance.post('/a/signup/', authData).then(response => {

                let success = response.data.success;
                if (success === true) {
                    commit('authUser', { username: response.data.email, team: response.data.team, token: response.data.token });
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('username', authData.username);
                    resolve(response.data);
                } else {


                    reject(response.data);
                }
            })
                .catch((error) => {
                    // eslint-disable-next-line

                    reject(error);
                });
        })
    },
    login: ({ commit }, authData) => {
        return new Promise((resolve, reject) => {
            instance.post('/a/login/', authData).then(response => {
                let success = response.data.success;
                if (success === true) {
                    commit('authUser', { username: response.data.email, team: response.data.team, token: response.data.token });
                    //commit('authUserData', { user_data: response.data.user_data });
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('username', authData.username);

                    localStorage.setItem('countryCode', response.data.user_data.countryCode);
                    localStorage.setItem('distanceUnit', response.data.user_data.distanceUnit);
                    localStorage.setItem('latitude', response.data.user_data.latitude);
                    localStorage.setItem('longitude', response.data.user_data.longitude);

                    resolve(response.data);
                }
                else {

                    reject(response.data);
                }
            })
                .catch((error) => {

                    reject(error.response.data);
                });
        })
    },
    autoLogin({ commit }) {
        let token = localStorage.getItem('token');
        let username = localStorage.getItem('username');

        if (!token || !username) {
            return;
        }

        commit('authUser', { username: username, token: token });
    },
    logout: ({ commit }) => {
        commit('clearAuthData');
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        //router.replace('login');
        ///m/logout/
        instance.post('/a/logout/').then(response => {

            router.replace('login');
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}