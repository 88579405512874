<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 20px;
      padding-right: 20px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <h3 class="widget-title">Onboarding</h3>
        </div>
      </section>
      <section class="fw-section">
        <div class="container">
          <ul>
            <li>
              <strong>1.</strong>
              <i
                class="material-icons edit text-default"
                style="
                  font-size: 18px;
                  padding-bottom: 5px;
                  padding-right: 5px;
                  padding-left: 10px;
                "
              ></i>
              <strong>Name your team</strong>
            </li>

            <li>
              <strong>2.</strong>
              <i
                class="material-icons edit_location text-default"
                style="
                  font-size: 18px;
                  padding-bottom: 5px;
                  padding-right: 5px;
                  padding-left: 10px;
                "
              ></i>
              <strong>Add depot details</strong>
            </li>
            <li>
              <strong>3.</strong>
              <i
                class="material-icons people text-default"
                style="
                  font-size: 18px;
                  padding-bottom: 5px;
                  padding-right: 5px;
                  padding-left: 10px;
                "
              ></i>
              <strong>Invite team members</strong>
            </li>
          </ul>
        </div>
      </section>
    </article>
  </v-container>
</template>

<script>
//import MessageBox from './dialog'
//import { create } from 'vue-modal-dialogs'

//const messageBox = create(MessageBox, 'content')

export default {
  data: () => ({
    content: "Hello World!",
  }),
  methods: {
    save() {
      var pref = {
        distanceUnit: localStorage.distanceUnit,
        name: localStorage.name,
        startAddress: localStorage.startAddress,
        startlat: localStorage.startLat,
        startlng: localStorage.startLng,
      };
      this.$store.dispatch("savePreferences", pref).then(() => {
        this.$router.push({ name: "Routes" });
      });

      //this.$router.push({ name: "Routes" });
      // for (var i = 0; i < this.$store.getters.stops.length; i++) {
      //   if (this.$store.getters.stops[i].id == this.id) {
      //     var stop = this.$store.getters.stops[i];
      //     stop.note = this.noteModel;
      //     this.$store.dispatch("editStop", stop).then(() => {
      //       this.$router.go(-1);
      //     });
      //   }
      // }
    },
  },
};
</script>

<style scoped>
ul li::before {
  content: "";
}

ul li {
  content: "";
}
</style>