<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 0px;
      padding-right: 0px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <div></div>

          <div class="row">
            <div class="col-sm-8">
              <table
                class="table table-borderless"
                style="border: none !important"
              >
                <tr>
                  <td style="width: 80%; text-align: left">
                    <router-link to="" @click.native="showRoutes()"
                      ><span class="material-icons" style="font-size: 36px">
                        chevron_left
                      </span>
                      <div class="headerLine">Dashboard</div></router-link
                    >
                  </td>
                  <td
                    style="width: 10%; text-align: right; font-size: 24px"
                  ></td>
                </tr>
              </table>
            </div>
            <div class="col-sm-4 text-right">
              <nav class="topbar-menu">
                <v-menu open-on-hover bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <i
                        class="material-icons more_vert"
                        style="
                          font-size: 24px;
                          padding-bottom: 5px;
                          padding-right: 15px;
                          color: #002e36;
                        "
                      ></i>
                    </v-btn>
                  </template>

                  <v-list>
                    <ul class="menu">
                      <div id="menu-item">
                        <i
                          class="material-icons account_circle"
                          style="
                            font-size: 24px;
                            padding-bottom: 5px;
                            color: #002e36;
                          "
                        ></i>
                        <div
                          class="email"
                          style="display: inline; vertical-align: baseline"
                        >
                          {{ currentEmail }}
                        </div>
                      </div>

                      <hr
                        class="hr-solid hr-primary"
                        style="
                          border-top-color: ;
                          border-top-width: 1px;
                          opacity: 0.25;
                        "
                      />

                      <!-- <li
                        class="menu-item"
                        style="padding: 5px 20px 5px 20px"
                        @click="openPreferences()"
                      >
                        <i
                          class="material-icons settings"
                          style="
                            font-size: 18px;
                            padding: 0 5px 10px 0px;
                            color: #002e36;
                          "
                        ></i
                        >Preferences
                      </li> -->

                      <li
                        class="menu-item"
                        style="padding: 5px 20px 5px 20px"
                        @click="logout()"
                      >
                        <i
                          class="material-icons person_outline"
                          style="
                            font-size: 18px;
                            padding: 0 5px 10px 0px;
                            color: #002e36;
                          "
                        ></i
                        >Logout
                      </li>
                    </ul>
                  </v-list>
                </v-menu>
              </nav>
            </div>
          </div>

          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
        </div>
      </section>
    </article>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
//import Calendar from "v-calendar/lib/components/calendar.umd";
//import DatePicker from "v-calendar/lib/components/date-picker.umd";
export default {
  data() {
    return {
      isLogged: false,
    };
  },
  methods: {
    showRoutes() {
      this.$router.push({ name: "Routes" });
    },

    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: "Login" });
      });
    },
  },
  computed: {
    ...mapGetters("auth", {
      isAuth: "isAuthenticated",
    }),
    routes() {
      return this.$store.getters.routes;
    },
    routesCount() {
      return this.$store.getters.routes.length;
    },
    currentEmail() {
      return this.$store.state.auth.username;
    },
    currentTeam() {
      return this.$store.state.auth.teamname;
    },
  },
};
</script>

<style scoped>
/* ul li:hover {
  background-color: #f1f1f1;
  cursor: pointer;
} */

.lititle1 {
  color: #002e36;
  font-weight: bold;
  font-size: 14px;
}

#innerExPan {
  padding-top: 0px;
  padding-right: 00px;
  padding-bottom: 0px;
  padding-left: 00px;
}

ul li::before {
  content: "";
}

ul li {
  content: "";
}
.v-application ul,
.v-application ol {
  padding: 0px;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.email {
  color: #969696;
  font-size: 14px;
  margin-left: 10px;
}

.team {
  color: #002e36;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

v-list v-list-item:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

#menu-item {
  padding: 10px 20px 0px 20px;
}
</style>