<template>
  <v-container
    fluid
    style="min-height: 0; padding-left: 40px; padding-right: 40px"
  >
    <article class="page text-left mobile-center padding-top-1x">
      <div>
        <div class="row padding-bottom-3x">
          <h4>Manage your Team</h4>
        </div>
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-6"></div>
          <div class="col-sm-4">
            <button
              class="btn btnl1 btn-block btn-sm"
              @click="addUser(null)"
              type="submit"
            >
              <span class="material-icons"> add </span>
              Add members
            </button>
          </div>
        </div>

        <table class="table table-borderless">
          <thead>
            <tr>
              <th>Email</th>
              <th>Name</th>
              <th>Role</th>
              <th>Subscription</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, i) in team" :key="i" @click="editStop(stop.id)">
              <th scope="row">
                <span class="material-icons">directions_car</span>
                {{ user.email }}
              </th>
              <td>{{ user.name }}</td>
              <td>{{ getRoles(user) }}</td>

              <td>
                <div v-if="user.plan_id == -1">TRIAL</div>
                <div v-if="user.plan_id == 1">PRO - Google Play</div>
                <div v-if="user.plan_id == 0">PRO Maposcope</div>
                <!-- <div v-if="i == 0">
                  PRO - Google Play Subscription<br />
                  valid: 2023-09-07
                </div>
                <div v-if="i == 1">
                  PRO - Stripe<br />
                  valid: 2023-09-07
                </div>
                <div v-if="i == 2">Trial <br /></div> -->
              </td>
              <td style="text-align: right">
                <v-menu bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ma-2" v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="addUser(user)"
                      >Edit member info
                    </v-list-item>
                    <v-list-item
                      @click="deleteUser(user)"
                      :disabled="user.is_current_user"
                    >
                      Delete member
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <v-dialog v-model="showDialogDeleteUser" persistent max-width="320">
        <v-card>
          <v-card-title class="text-h5"> Delete member </v-card-title>
          <v-card-text class="text-h6"
            >Do you really want to delete "{{ this.email }}"?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="showDialogDeleteUser = false"
              class="text-h6"
            >
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="deleteUser2()"
              class="text-h6"
            >
              Delete member
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog"
        max-width="600px"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar color="#015a66"
            ><div class="white1">User Profile</div></v-toolbar
          >
          <v-card-text>
            <v-container fluid="true">
              <v-row>
                <v-col cols="12" sm="12">
                  <div class="row padding-bottom-2x"></div>
                  <label for="name">Enter email address:</label>
                  <v-text-field
                    :rules="rules"
                    id="email"
                    name="email"
                    v-model="email"
                    type="text"
                    value=""
                    :disabled="isEmailDisabled"
                    :placeholder="'Email addres'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12">
                  <label for="name">Enter full name:</label>
                  <input
                    id="name"
                    name="name"
                    v-model="name"
                    type="text"
                    value=""
                    :placeholder="'Full name'"
                    style="background-color: #ffffff; border-color: #c5c5c5"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12">
                  <div>
                    <multiselect
                      v-model="roles"
                      :options="role_items"
                      :multiple="true"
                      :close-on-select="true"
                      placeholder="Pick roles"
                    >
                    </multiselect>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <small>*required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#015a66" text @click="dialog = false" class="text-h6">
              Close
            </v-btn>

            <v-btn color="#015a66" text @click="saveUser()" class="text-h6">
              Save
            </v-btn>
          </v-card-actions>
          <div class="row padding-bottom-4x"></div>
        </v-card>
      </v-dialog>
    </article>
    <dialogConfirm ref="confirm"></dialogConfirm>
  </v-container>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data: () => ({
    team: [],
    dialog: false,
    showDialogDeleteUser: false,
    email: "",
    name: "",
    roles: [],
    rules: [
      (value) => !!value || "Required.",
      (value) => (value || "").length <= 128 || "Max 128 characters",
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    ],
    id: -1,
    isEmailDisabled: false,
    role_items: ["Driver", "Dispatcher"],
    value: "",
    options: [
      "Select option",
      "options",
      "selected",
      "mulitple",
      "label",
      "searchable",
      "clearOnSelect",
      "hideSelected",
      "maxHeight",
      "allowEmpty",
      "showLabels",
      "onChange",
      "touched",
    ],
  }),
  watch: {
    name(newName) {
      localStorage.name = newName;
    },
  },
  methods: {
    getRoles(user) {
      var rv = "";
      if (user.role & 1) {
        rv = this.role_items[0];
      }
      if (user.role & 2) {
        if (user.role & 1) {
          rv += ", ";
        }
        rv += this.role_items[1];
      }

      return rv;
    },
    addUser(user) {
      this.dialog = true;
      if (user != null) {
        this.isEmailDisabled = true;
        this.email = user.email;
        this.name = user.name;
        this.id = user.id;
        this.roles = [];
        if (user.role & 1) {
          this.roles.push(this.role_items[0]);
        }
        if (user.role & 2) {
          this.roles.push(this.role_items[1]);
        }
      } else {
        this.roles = [];
        this.roles.push(this.role_items[0]);
        this.isEmailDisabled = false;
        this.email = "";
        this.name = "";
        this.id = -1;
      }
    },
    async deleteUser(user) {
      if (user != null) {
        if (
          await this.$refs.confirm.openConfirm(
            "Delete member",
            `Do you really want to delete - ${user.email}?`,
            {
              color: "blue",
            }
          )
        ) {
          // yes
          this.deleteUser2(user.id);
        } else {
          // cancel
        }
      }
    },
    deleteUser2(user_id) {
      if (user_id != null) {
        this.$store.dispatch("deleteUser", user_id).then(() => {
          this.$store.dispatch("getTeam").then((response) => {
            this.team = response.data.team;
          });
        });
      }
    },
    async showInfo(title, msg) {
      if (
        await this.$refs.confirm.openInfo(title, msg, {
          color: "blue",
        })
      ) {
        // yes
      }
    },
    saveUser() {
      if (this.roles.length == 0) {
        this.showInfo("Error", `Please set at least one role (e.g. driver)`);
        return;
      }
      var role = 0;
      for (var i = 0; i < this.roles.length; i++) {
        if (this.roles[i] == this.role_items[0]) {
          role |= 1;
        }
        if (this.roles[i] == this.role_items[1]) {
          role |= 2;
        }
      }

      this.dialog = false;
      if (this.id != null) {
        var p = {
          name: this.name,
          email: this.email,
          user_id: this.id,
          role: role,
        };

        this.$store.dispatch("editTeamMember", p).then((response) => {
          //
          if (response.data.err < 0 && response.data.message != null) {
            this.showInfo("Error", `${response.data.message}`);
          } else {
            this.$store.dispatch("getTeam").then((response) => {
              this.team = response.data.team;
            });
          }
        });
      }
    },
  },
  created() {
    this.$store.dispatch("getTeam").then((response) => {
      this.team = response.data.team;
    });
  },
};
</script>

<style scoped>
.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}
</style>
