<template>
  <v-container
    fluid
    style="min-height: 0; padding-left: 40px; padding-right: 40px"
  >
    <article class="page text-left mobile-center padding-top-1x">
      <div>
        <div class="row padding-bottom-3x">
          <h4>Confirm my cancellation</h4>
        </div>

        <div class="row padding-bottom-6x"></div>
        <div class="row">
          <div class="border1">
            <div class="row"><h4>Cancel Subscriptions</h4></div>
            <div class="row padding-bottom-1x"></div>

            <div class="row padding-bottom-1x"></div>
            <div class="row">
              <v-btn
                :loading="loading"
                x-large
                color="error"
                @click="cancelSubscriptionNow()"
              >
                <div class="btn">
                  Confirm cancelation <v-icon right dark> warning </v-icon>
                </div>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="row padding-bottom-1x"></div>

        <div class="row">
          <span style="color: red"
            ><v-icon right left style="color: red"> error </v-icon> Please note
            that if you cancel your subscription, you will lose all your
            rankings and keywords history.
          </span>
        </div>
      </div>
      <dialogConfirm ref="confirm"></dialogConfirm>
    </article>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    currentPlan: "Pro", //Basic (Trial) / Pro
    name: "",
    loader: null,
    loading: false,
  }),
  methods: {
    upgradePlan() {
      this.loader = "loading";
    },
    cancelSubscriptionNow() {
      this.loader = "loading";
      this.$store.dispatch("cancelSubscription").then((response) => {
        this.loader = null;
        this.loading = false;
        if (response.data.err == 0) {
          this.$router.push({ name: "Canceled" });
        }
      });
    },
  },
  watch: {
    name(newName) {
      localStorage.name = newName;
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
  color: #ffffff;
  background-color: red;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

