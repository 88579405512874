<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 0px;
      padding-right: 0px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <!-- header -->

          <div>
            <div class="stop_name">{{ nameModel }}</div>
            <div class="stop_address">{{ addressModel }}</div>
          </div>
          <div class="row padding-bottom-2x"></div>
          <div>
            <label for="note" class="label_row"
              ><i class="material-icons description icon-blue"></i
              ><span class="text">Note</span></label
            >
            <textarea
              cols="40"
              id="note"
              name="note"
              rows="2"
              v-model="noteModel"
              placeholder="add notes"
              style="background-color: #ffffff; border-color: #c5c5c5"
              :disabled="isOptimized"
            ></textarea>
          </div>
          <br />

          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
          <div>
            <label for="x1" class="label_row"
              ><i
                class="material-icons priority_high icon-blue"
                v-bind:style="styleObjectPriority"
              ></i
              ><span class="text">Priority</span></label
            >

            <div class="row">
              <div class="col-sm-4"></div>
              <div class="col-sm-4">
                <input
                  type="radio"
                  id="one"
                  value="1"
                  v-model="priorityModel"
                  :disabled="isOptimized"
                />
                <label for="one">ASAP</label>
              </div>
              <div class="col-sm-4">
                <input
                  type="radio"
                  id="two"
                  value="0"
                  v-model="priorityModel"
                  :disabled="isOptimized"
                />
                <label for="two">Normal</label>
              </div>
            </div>
          </div>
          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
          <div>
            <div class="row">
              <div class="col-sm-8">
                <label for="name" class="label_row"
                  ><i class="material-icons timer icon-blue"></i
                  ><span class="text"> Visit Time (in minutes)</span></label
                >
                <div class="small_input">
                  <input
                    id="serviceTimeModel"
                    name="serviceTimeModel"
                    v-model="serviceTimeModel"
                    required
                    type="number"
                    min="0"
                    max="10000"
                    value="10"
                    style="background-color: #ffffff; border-color: #c5c5c5"
                    :disabled="isOptimized"
                  />
                </div>
              </div>
            </div>

            <label for="x2" class="label_row"
              ><i class="material-icons schedule icon-blue"></i
              ><span class="text"> Arrive between</span></label
            >
            <div class="row">
              <div class="col-sm-4">
                <label for="name">Since</label>
                <vue-timepicker
                  v-model="timeValueSince"
                  format="HH:mm"
                  drop-direction="up"
                  @change="changeHandlerSince"
                  :disabled="isOptimized"
                ></vue-timepicker>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-4">
                <label for="email">To</label>
                <vue-timepicker
                  style="background-color: #f3f3f3; border-color: #c5c5c5"
                  v-model="timeValueTo"
                  format="HH:mm"
                  drop-direction="up"
                  @change="changeHandlerTo"
                  :disabled="isOptimized"
                ></vue-timepicker>
              </div>
            </div>
            <!--------- recipient info ---------->
            <hr
              class="hr-solid hr-primary"
              style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
            />

            <div>
              <div class="text5">Recipient information</div>
              <label for="recipeintNameModel">Recipient name</label>
              <input
                id="recipeintNameModel"
                name="recipeintNameModel"
                v-model="recipeintNameModel"
                type="text"
                value=""
                style="background-color: #ffffff; border-color: #c5c5c5"
                :disabled="isOptimized"
              />
              <label for="recipeintPhoneModel"
                >Phone number (format +XXXXXXXXXXX)</label
              >

              <v-text-field
                :rules="rules"
                v-model="recipeintPhoneModel"
                :disabled="isOptimized"
              ></v-text-field>

              <br />

              <label for="recipeintEmailModel">Email address</label>
              <input
                id="recipeintEmailModel"
                name="recipeintEmailModel"
                v-model="recipeintEmailModel"
                type="text"
                value=""
                style="background-color: #ffffff; border-color: #c5c5c5"
                :disabled="isOptimized"
              />
            </div>

            <!--------- order info ---------->
            <hr
              class="hr-solid hr-primary"
              style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
            />

            <div class="text5">Order information</div>
            <label for="packageCountModel">Package count</label>
            <input
              id="packageCountModel"
              name="packageCountModel"
              v-model="packageCountModel"
              type="number"
              value=""
              style="background-color: #ffffff; border-color: #c5c5c5"
              :disabled="isOptimized"
            />

            <label for="productsModel">Products</label>
            <input
              id="productsModel"
              name="productsModel"
              v-model="productsModel"
              type="text"
              value=""
              style="background-color: #ffffff; border-color: #c5c5c5"
              :disabled="isOptimized"
            />

            <label for="productsBarcodeModel"
              >Products barcode list (format: add barcode and separate with
              commas, eg. 12312312, 12737373, 13848838)</label
            >
            <input
              id="productsBarcodeModel"
              name="productsBarcodeModel"
              v-model="productsBarcodeModel"
              type="text"
              value=""
              style="background-color: #ffffff; border-color: #c5c5c5"
              :disabled="isOptimized"
            />

            <div v-if="isOptimized">
              <!-- {{ productsBarcodeScannedModel }} -->
              <label>Barcode list:</label>
              <ul>
                <li v-for="item in productBarcodesScanned" :key="item.message">
                  <i
                    v-if="item.checked"
                    class="material-icons check_circle icon-green"
                  ></i>
                  <i v-else class="material-icons remove_circle icon-red"></i>

                  <span class="text">{{ item.message }}</span>
                </li>
              </ul>
            </div>
            <!--------- order info end ---------->
          </div>
        </div>
      </section>
    </article>
  </v-container>
</template>

<script>
import { EventBus } from "./event-bus";
import VueTimepicker from "vue2-timepicker";

export default {
  props: ["stopId", "closeProp", "value"],

  components: { VueTimepicker },
  data() {
    return {
      showDeleteMessage: false,

      nameModel: "not set",
      addressModel: "not set",
      noteModel: "",
      serviceTimeModel: "5",

      priorityModel: "",
      priorityIcon: "",
      styleObjectPriority: {
        color: "red",
        fontSize: "24px",
      },
      timeValueSince: "",
      timeValueTo: "",
      //isOptimized: 0,

      recipeintNameModel: "",
      recipeintPhoneModel: "",
      recipeintEmailModel: "",
      packageCountModel: "",
      productsModel: "",
      productsBarcodeModel: "",
      productsBarcodeScannedModel: "",
      stop: null,
      rules: [
        (value) => {
          if (value.length == 0) {
            return true;
          }
          const pattern = /^\+[0-9]{11}$/;
          return pattern.test(value) || "Invalid phone number";
        },
      ],
    };
  },
  methods: {
    changeHandlerSince(eventData) {
      if (!eventData.data.HH) {
        this.timeValueSince = "08:" + eventData.data.mm;
      }
      if (!eventData.data.mm) {
        this.timeValueSince = eventData.data.HH + ":00";
      }
    },
    changeHandlerTo(eventData) {
      if (!eventData.data.HH) {
        this.timeValueTo = "08:" + eventData.data.mm;
      }
      if (!eventData.data.mm) {
        this.timeValueTo = eventData.data.HH + ":00";
      }
    },

    save() {
      if (this.$store.getters.getRouteData("is_optimized")) return;

      for (var i = 0; i < this.$store.getters.stops.length; i++) {
        if (this.$store.getters.stops[i].id == this.id) {
          var stop = this.$store.getters.stops[i];
          stop.note = this.noteModel;
          stop.service_time = this.serviceTimeModel;
          stop.priority = this.priorityModel;
          stop.time_window_start = 0;
          if (this.timeValueSince) {
            var t1 = this.timeValueSince.split(":");
            var mins1 = parseInt(t1[0]) * 60 + parseInt(t1[1]);
            if (mins1) stop.time_window_start = mins1; //e.g. 08:23
          }
          stop.time_window_end = 0;

          if (this.timeValueTo) {
            var t = this.timeValueTo.split(":");
            var mins = parseInt(t[0]) * 60 + parseInt(t[1]);

            if (mins) stop.time_window_end = mins; //e.g. 08:23
          }
          stop.recipient_name = this.recipeintNameModel;
          if (
            this.recipeintPhoneModel != null &&
            this.isValidPhoneNumber(this.recipeintPhoneModel)
          ) {
            stop.recipient_phone_number = this.recipeintPhoneModel;
          }
          stop.recipient_email = this.recipeintEmailModel;

          if (
            this.packageCountModel != null &&
            this.packageCountModel.length > 0 &&
            !isNaN(this.packageCountModel)
          ) {
            stop.order_info_package_count = this.packageCountModel;
          } else {
            stop.order_info_package_count = null;
          }
          stop.order_info_products = this.productsModel;

          if (
            this.productsBarcodeModel != null &&
            this.productsBarcodeModel.length > 0
          ) {
            stop.order_info_products_barcodes =
              this.productsBarcodeModel.replace(/\s/g, "");
          }

          this.$store.dispatch("editStop", stop).then(() => {
            //this.$router.go(-1);
            //cloaseme?
          });
        }
      }
      //this.clearData();
    },

    clearData() {
      this.recipeintNameModel = "";
      this.recipeintPhoneModel = "";
      this.recipeintEmailModel = "";
      this.packageCountModel = "";
      this.productsModel = "";
      this.productsBarcodeModel = "";
      this.productsBarcodeScannedModel = "";
    },
    isValidPhoneNumber(p) {
      var rv = p.match(/^\+\d{11}$/g);
      if (rv) return true;
      return false;
    },
    refreshData() {
      this.clearData();

      this.recipeintPhoneModel = "123x";
      this.id = this.stopId; //this.$route.params.id;
      for (var i = 0; i < this.$store.getters.stops.length; i++) {
        if (this.$store.getters.stops[i].id == this.id) {
          this.stop = this.$store.getters.stops[i];

          this.recipeintNameModel = this.stop.recipient_name;
          this.recipeintPhoneModel = this.stop.recipient_phone_number;
          this.recipeintEmailModel = this.stop.recipient_email;
          this.packageCountModel = this.stop.order_info_package_count;
          this.productsModel = this.stop.order_info_products;
          this.productsBarcodeModel = this.stop.order_info_products_barcodes;
          this.productsBarcodeScannedModel =
            this.stop.order_info_products_barcodes_scanned;
          this.nameModel = this.$store.getters.stops[i].name;
          this.addressModel = this.$store.getters.stops[i].address;
          this.noteModel = this.$store.getters.stops[i].note;

          this.serviceTimeModel = this.$store.getters.stops[i].service_time;
          this.priorityModel = this.$store.getters.stops[i].priority;
          const zeroPad = (num, places) => String(num).padStart(places, "0");
          if (
            this.$store.getters.stops[i].time_window_start &&
            this.$store.getters.stops[i].time_window_start > 0
          ) {
            let k = this.$store.getters.stops[i].time_window_start;
            this.timeValueSince =
              zeroPad(parseInt(k / 60), 2) + ":" + zeroPad(k % 60, 2);
          }

          if (
            this.$store.getters.stops[i].time_window_end &&
            this.$store.getters.stops[i].time_window_end > 0
          ) {
            let k = this.$store.getters.stops[i].time_window_end;
            this.timeValueTo =
              zeroPad(parseInt(k / 60), 2) + ":" + zeroPad(k % 60, 2);
          }

          EventBus.$emit("SHOW_MARKER_EVENT", this.id);
          break;
        }
      }
    },
  },
  computed: {
    count() {
      return this.$store.state.count;
    },
    isOptimized() {
      return this.$store.getters.getRouteData("is_optimized") == 1;
    },
    productBarcodesScanned() {
      var items2 = [];
      var predefinied = [];
      var scanned = [];
      var scannedAlreadyAdded = new Set();

      if (this.productsBarcodeModel) {
        predefinied = this.productsBarcodeModel.split(",");
      }

      if (this.productsBarcodeScannedModel) {
        scanned = this.productsBarcodeScannedModel.split(",");
      }

      for (var i = 0; i < predefinied.length; i++) {
        var isScanned = 0;
        for (var j = 0; j < scanned.length; j++) {
          if (predefinied[i].trim() == scanned[j].trim()) {
            isScanned = 1;
            scannedAlreadyAdded.add(j);
            break;
          }
        }
        items2.push({ message: predefinied[i].trim(), checked: isScanned });
      }

      for (var k = 0; k < scanned.length; k++) {
        if (!scannedAlreadyAdded.has(k)) {
          items2.push({ message: scanned[k].trim(), checked: 1 });
        }
      }

      return items2;
    },
  },
  created() {},
  mounted() {
    this.refreshData();
  },
  watch: {
    priorityModel(newPriorityModel) {
      if (newPriorityModel == 0) {
        //normal
        this.styleObjectPriority.color = "#1d77f4";
      } else {
        //asap
        this.styleObjectPriority.color = "red";
      }
    },
    stopId() {
      this.refreshData();
    },
    closeProp: function () {
      //
      //
      //
      this.save();
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>


<style >
.stop_name {
  font-size: 22px;
  line-height: 24px;
  font-weight: bold;
  padding-bottom: 10px;
}
.stop_address {
  color: #181818;
  font-size: 14px;
  font-family: sans-serif;
}

i.icon-blue {
  color: #1d77f4;
  font-size: 22px;
}

.small_input {
  width: 100px;
}

.label_row {
  height: 30px;
  line-height: 30px;
}

.text {
  display: inline-block;
  vertical-align: middle;
  line-height: 18px; /* <-- adjust this */
  padding: 5px 0 0 10px;
}

input-box {
  background-color: red;
}

.display-time {
  background-color: white !important;
  border-color: #ced9ee !important;
  font-size: 16px !important;
  list-style: square !important;
}

.vue__time-picker .dropdown ul li::before {
  content: "" !important;
  font-size: 16px;
}
</style>