<template>
  <v-container
    fluid
    style="min-height: 0; padding-left: 40px; padding-right: 40px"
  >
    <article class="page text-left mobile-center padding-top-1x">
      <div>
        <div class="row padding-bottom-3x">
          <h4>Personal Profile</h4>
        </div>

        <div class="row padding-bottom-1x">
          <label>Email address:</label>
          {{ email }}
        </div>

        <div class="row padding-bottom-1x">
          <label for="name">Full name:</label>
          <input
            id="name"
            name="name"
            v-model="name"
            type="text"
            value=""
            :placeholder="'enter display name in the app'"
            style="background-color: #ffffff; border-color: #c5c5c5"
          />
        </div>
      </div>
    </article>
  </v-container>
</template>

<script>
export default {
  components: {},
  data: () => ({
    email: "",
    name: "",
  }),
  watch: {
    name(newName) {
      localStorage.name = newName;
    },
  },
  created() {
    this.$store.dispatch("getPreferences").then((response) => {
      this.name = response.data.preferences.name;
      this.email = response.data.preferences.email;
    });
  },
};
</script>

